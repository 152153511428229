'use strict';

import React from 'react';

import validation from './validation';

import { shallowCopy } from './helpers';
import { submitContactFormLearners } from './ajax';


class ContactUsLearners extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        message: '', // Message What would you like to discuss or what is your question?
      },
      validate: false,
      tree: null,
      state: 'new',
    };
    this.merge = this.merge.bind(this);
    this.validate = this.validate.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }
  merge(newData) {
    let form = shallowCopy(this.state.form, newData);
    this.setState({
      form,
      tree: this.validate(null, form)
    });
  }
  validate(attribute, newData) {
    // console.log('what are we merging?', { attribute, newData });
    let data = newData ? newData : this.state.form;

    // if attribute is provided, return empty string or CSS class to add to invalid field
    if (attribute) {
      if (this.state.validate) {
        // check whether attribute inside validation tree is valid, if not return 'error' CSS class
        if (this.state.tree && this.state.tree[attribute] && this.state.tree[attribute].length > 0) {
          return 'error';
        }
        return '';
      } else {
        return '';
      }
    } else {
      // generate validation tree
      let tree = {
        message: validation.validateString(data.message) ? null : 'Message is required.',
      };
      return tree;
    }
  }

  async componentDidMount() {
    try {
      console.log('[ContactUs] Handling componentDidMount()...');

      // start timer here to periodically refetch user?
    } catch(e) {
      console.error('[ContactUs] Error handling componentDidMount().', e);
      this.setState({ loggedIn: false });
    }
  }
  async onSubmit() {
    try {
      // console.log('submitting...');
      let tree = this.validate(null, this.state.form);
      // console.log('we have a validation tree', tree);
      let isHealthy = validation.isTreeHealthy(tree);
      this.setState({
        tree,
        validate: true
      });
      if (isHealthy) {
        let data = {
          message: this.state.form.message,
        };
        // console.log('we have a valid form.', data);
        this.setState({ state: 'sending' });

        let result = await submitContactFormLearners(data);
        // console.log('done with submission, got response from server.', result);
        // if (window) {
        //   window.scrollTo(0, 0);
        // }
        this.setState({ state: 'sent' });
      } else {
        // console.log('we have an invalid form', this.state.form);
      }
    } catch(e) {
      console.error(`Error handling onSubmit().`, e);
    }
  }

  render() {

    if (this.state.state === 'sending' || this.state.state === 'sent') {
      return (
        <div className='pi3narrow pi3form'>

          <div className="formRow">
            <label htmlFor="input-message">Message</label>
            <textarea disabled id='input-message' name='message' value={this.state.form.message} />
          </div>

          {
            this.state.state === 'sending' &&
            <div className="formRow submitRow">
              <button className='pi3button disabled'>Sending...</button>
            </div>
          }

          {
            this.state.state === 'sent' &&
            <div>
              <div className="formRow submitRow">
                <button className='pi3button disabled'>Sent.</button>
              </div>
              <p>
                Thank you for reaching out! We will be in touch shortly.
              </p>
            </div>
          }

        </div>

      );
    }

    return (

      <div className='pi3narrow pi3form'>

        <div className="formRow">
          <label htmlFor="input-message">Message</label>
          <textarea className={` ${this.validate('message')}`} id='input-message' name='message' value={this.state.form.message} onChange={e => this.merge({ message: e.target.value })} placeholder='What would you like to discuss or what is your question?' />
        </div>
        <div className="formRow submitRow">
          <button className='pi3button' onClick={this.onSubmit}>Submit</button>
        </div>

      </div>

    );
  }
}

export default ContactUsLearners;

