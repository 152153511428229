
import {useRef} from 'react';

// @see https://stackoverflow.com/questions/65027884/detect-first-render-in-a-react-component/66139558#66139558
export const useFirstRender = (setup) => {
  const ref = useRef(true);
  const isFirstRender = ref.current;
  ref.current = false;
  if (isFirstRender)
    setup();
};
