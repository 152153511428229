import React from 'react';


// CompanyOnboarding2ThankYouWire2ElectricBoogaloo
// <CompanyOnboarding2ThankYouWire email={this.state.form.email} />
class CompanyOnboarding2ThankYouWire extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {

    return (
      <div className='pi2thanks'>
        <img src="https://pi2-web.s3.amazonaws.com/img/pi-cat_head%20white_final_CS5.png" alt="" />
        <h1>Order Received</h1>
        <p>
          {/* We've sent an invoice to <a href={this.props.email}>{this.props.email}</a>. */}
          {/* Please check your email for detailed instructions on how to complete your wire transfer. */}
          Please check <a href={this.props.email}>{this.props.email}</a> for details on how to complete your wire transfer.
          <br />
          Within 48 hours of receiving your payment, we will help you get set up so you can onboard your team.
          <br />
          Thank you for choosing Product Institute!
        </p>
        <a href='/'>
          <button className='pi4button'>Return to Product Institute</button>
        </a>
      </div>
    );

  }
}


export default CompanyOnboarding2ThankYouWire;