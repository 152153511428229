
import React from 'react';

export const GList = function (props) {

  return (<>
    <ul
      style={{
        listStyleType: 'none',
        padding: 0,
        margin: 0,
        boxSizing: 'border-box',
        position: 'relative',
        width: '100%',
        height: '100%',
        ...props?.style,
      }}
    >
      {props?.children}
    </ul>
  </>);

};
