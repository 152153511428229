
import React from 'react';

export const GSelectOption = function (props) {

  return (<>
    <option value={props.value}>
      {props?.children}
    </option>
  </>);

};
