
export const isString = (value) => {
  return ['[object String]'].includes({}.toString.call(value));
};

export const isArray = (value) => {
  return Array.isArray(value) || ['[object Array]'].includes({}.toString.call(value));
};

export const toAutoArray = (value) => {
  return isArray(value) ? value : [value];
};

export const isObject = (value) => {
  return ['[object Object]'].includes({}.toString.call(value));
};

export const isFunction = (value) => {
  return ['[object Function]', '[object AsyncFunction]'].includes({}.toString.call(value));
};
