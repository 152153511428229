
import React from 'react';
import {useState} from 'react';
import {valueListToArray} from '../../../../src/utilities/Array.js';

export const GButton = function (props) {

  const [hover, setHover] = useState(false);

  return (<>
    <button
      className={valueListToArray({
        "pi3button": true,
        "pi3activeButton": valueListToArray(props?.variant).includes('active'),
      }).join(' ')}
      style={{
        ...props?.style,
      }}
      onMouseEnter={(e) => { setHover(true); }}
      onMouseLeave={(e) => { setHover(false); }}
      onClick={(e) => { props?.onClick?.(e); }}
      disabled={!!valueListToArray(props?.variant).includes('disabled')}
    >
      {props?.children}
    </button>
  </>);

};
