
import React from 'react';
import {createRoot} from 'react-dom/client';
import {useAPI} from '../../../src/pi3-frontend/API.js';

export const createCompanyDeleteDialog = async function (props) {

  const api = useAPI();

  //createRoot(document.createElement('div')).render(React.createElement(CompanyDelete, {}));
  if (!window.confirm("Are you sure you want to delete the selected company?"))
    return;

  await api.delete({url: `/api/v3/admin/company/${encodeURIComponent(props.id)}`});

  window.alert("Company deleted!");

  location.href = location.href;

};
