import React from 'react';

import { getQueryAttribute } from './helpers';

import {
  getCheckoutSession,
} from './ajax';


import { emitPageView, emitTeamsPurchaseComplete } from './analytics';

import LoadingCenter from './LoadingCenter';

import CompanyOnboarding2 from './CompanyOnboarding2';


// eslint-disable-next-line no-undef
const STRIPE_PUBKEY = __STRIPE_PUBLISHABLE_KEY__; 

var stripe = null;
if (typeof Stripe !== 'undefined') {
  // eslint-disable-next-line no-undef
  stripe = Stripe(STRIPE_PUBKEY);
} else {
  console.warn('[NewPurchaseFlow] Stripe library not loaded.');
}

class Pricing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      purchaseFlow: 'loading', // null, 'individuals', 'teams', 'loading'
      status: null,
      mode: null,
      invoice: null,
    };
    this.changeFlow = this.changeFlow.bind(this);
  }

  async componentDidMount() {
    try {
      // parse query strings
      let status = getQueryAttribute(window.location.search, 's'); // s
      let mode = getQueryAttribute(window.location.search, 'm'); // c
      let invoice = getQueryAttribute(window.location.search, 'in');
      let stripeCheckoutSessionId = getQueryAttribute(window.location.search, 'csid');
      let emailUser = getQueryAttribute(window.location.search, 'e');
      this.setState({ status, mode, invoice, stripeCheckoutSessionId });

      console.log('[Pricing] Parsed query strings.', { status, mode, invoice, stripeCheckoutSessionId });

      // checkout success teams - stripe checkout completed
      if (status === 's' && mode === 'c') {
        // send 'page view' event
        emitPageView('/purchase/teams/success-stripe', 'Purchase Teams - Success Stripe Checkout');
        // fetch checkout session
        let cs = await getCheckoutSession(stripeCheckoutSessionId);
        console.log('[Pricing] Fetched checkout session.', cs.session);

        let emailStripe = (cs.session.customer_details && cs.session.customer_details.email) ? cs.session.customer_details.email : null;
        let licenseType = (cs.session.metadata && cs.session.metadata.licenseType) ? parseInt(cs.session.metadata.licenseType) : null;
        let seats = (cs.session.metadata && cs.session.metadata.seats) ? parseInt(cs.session.metadata.seats) : null;
        // send 'teams stripe checkout complete' event
        emitTeamsPurchaseComplete(cs.session.amount_total, stripeCheckoutSessionId, emailUser, emailStripe, licenseType, seats); 
      }

      // not a success page, this is the default purchaseFlow
      else {
        this.setState({ purchaseFlow: 'teams' });
      }

    }
    catch(e) {
      console.error(`[Pricing] Error in componentDidMount()`, e);
    }
  }

  changeFlow(newPurchaseFlow) {
    console.log('[Pricing] Purchase flow changed.', { purchaseFlow: newPurchaseFlow });
    // TODO if changed from null to 'individuals', scroll down
    this.setState({ purchaseFlow: newPurchaseFlow });
  }
  render() {

    // company checkout succeeded, redirecting back from Stripe Checkout
    if (this.state.status === 's' && this.state.mode === 'c') {
      return (
        <div>
          <CompanyOnboarding2ThankYouStripe />
        </div>
      );
    }

    // show the teams purchase flow
    else if (this.state.purchaseFlow === 'teams') {
      // google analytics
      emitPageView('/purchase/teams', 'Purchase Teams');
      return (
        <div>
          <CompanyOnboarding2 flow={this.state.purchaseFlow} onChange={this.changeFlow} />
        </div>
      );
    }

    else if (this.state.purchaseFlow === 'loading') {
      return (
        <div>
          <LoadingCenter />
        </div>
      );
    }

    console.error('Pricing component landed in strange state.', { purchaseFlow: this.state.purchaseFlow });
    // this should not happen
    return '';

  }
}


export default Pricing;


class CompanyOnboarding2ThankYouStripe extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className='pi2thanks'>
        <img src="https://pi2-web.s3.amazonaws.com/img/pi-cat_head%20white_final_CS5.png" alt="" />
        <h1>Purchase Complete</h1>
        <p>
          Your purchase is complete.
          <br />
          You should receive an email with your invoice soon.
          <br />
          Look out for onboarding instructions sent to your email within 48 hours.
          <br />
          Thank you for choosing Product Institute!
        </p>
        <a href='/'>
          <button className='pi4button'>Return to Product Institute</button>
        </a>
      </div>
    );
  }
}


