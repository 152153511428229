
import {isString} from '../../src/utilities/Type.js';
import {routes} from '../../src/routes.js';

export const useRouter = function () {

  const routeToURI = (to) => {
    let uri = to;
    if (to?.name) {
      uri = null;
      for (const route of routes) {
        if (route.name == to.name)
          uri = route.path;
      }
      if (uri === null) {
        console.error(`Unregistered route \`${to.name}\``);
        return '/---';
      }
    }
    if (to?.path)
      uri = to.path;
    if (!isString(uri)) {
      console.error('Unable to process route', to);
      return '/---';
    }
    uri = uri.replace(/\/\:([a-zA-Z0-9\-_]+)(\?)?/g, (_, param, optional) => {
      if (!optional && !to?.params?.[param]) {
        console.error(`Undefined param \`${param}\``);
        return '/---';
      }
      return (to?.params && to?.params[param] !== undefined) ? ('/' + to?.params[param]) : '';
    });
    const query = Object.fromEntries(
      Object.entries(to?.query ?? {})
        .filter(e => (e[1] ?? null) !== null)
    );
    if (Object.keys(query).length > 0)
      uri += '?' + Object.keys(query)
        .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(to.query[k]))
        .join('&')
      ;
    return uri;
  };

  return {
    getCurrentRoute: () => {
      // @todo handle ssr
      return {
        path: typeof(location) != 'undefined' ? (new URL(location.href)).pathname : '',
        query: typeof(location) != 'undefined' ? Object.fromEntries((new URL(location.href)).searchParams) : {},
      };
    },
    resolve: (to) => {
      return {
        href: routeToURI(to),
      };
    },
    replace: (to) => {
      // @todo handle ssr
      if (typeof(location) == 'undefined')
        return;
      // @see https://developer.mozilla.org/en-US/docs/Web/API/History/replaceState#parameters
      window.history.replaceState(null, '', routeToURI({
        name: to?.name,
        path: to?.path ?? (new URL(location.href)).pathname,
        params: to?.params,
        query: {
          ...Object.fromEntries((new URL(location.href)).searchParams),
          ...to?.query,
        },
      }));
    },
  };

};
