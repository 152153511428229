
import React from 'react';

import LoadingShort from './LoadingShort';

class LoadingCenter extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
        <LoadingShort />
      </div>
    );
  }
}

export default LoadingCenter;