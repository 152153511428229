import React from 'react';


// <Image src='' alt='' style={} className='' width='' height='' viewBox='' />
function Image(props) {
  if (!props.src || props.src  === '') {
    return '';
  }

  let extension = props.src.split('.').pop();

  // SVG render
  if (extension && extension.toLowerCase() === 'svg') {
    return (
      <svg width={props.width} height={props.height} viewBox={props.viewBox} style={props.style} className={props.className}>
        <image href={props.src} width={props.width} height={props.height}/>
      </svg>
    );
  }
  // other images render
  else {
    return (
      <img src={props.src} alt={props.alt} style={props.style} className={props.className} />
    );
  }

  // return (
  //   <img src={props.src} alt={props.alt} style={props.style} className={props.className} />
  // );

}

export default Image;
