
import React from 'react';

export const GHeading = function (props) {

  return (<>
    <h1
      style={{
        ...props?.style,
      }}
    >
      {props?.children}
    </h1>
  </>);

};
