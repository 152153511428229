
import React from 'react';
import {createCompanyDeleteDialog} from '../../../src/pi3-frontend/helpers/Company.jsx';
import {DateTime} from 'luxon';
import {GAlign} from '../../../src/pi3-frontend/generics/GAlign/GAlign.jsx';
import {GBox} from '../../../src/pi3-frontend/generics/GBox/GBox.jsx';
import {GButton} from '../../../src/pi3-frontend/generics/GButton/GButton.jsx';
import {getCompanyList} from '../../../src/pi3-frontend/ajax-admin.js';
import {GFlexItem} from '../../../src/pi3-frontend/generics/GFlex/GFlexItem.jsx';
import {GFlex} from '../../../src/pi3-frontend/generics/GFlex/GFlex.jsx';
import {GHeading} from '../../../src/pi3-frontend/generics/GHeading/GHeading.jsx';
import {GInputSearch} from '../../../src/pi3-frontend/generics/GInputSearch/GInputSearch.jsx';
import {GListItem} from '../../../src/pi3-frontend/generics/GList/GListItem.jsx';
import {GList} from '../../../src/pi3-frontend/generics/GList/GList.jsx';
import {GRouterLink} from '../../../src/pi3-frontend/generics/GRouterLink/GRouterLink.jsx';
import {GSelectOption} from '../../../src/pi3-frontend/generics/GSelect/GSelectOption.jsx';
import {GSelectPlaceholder} from '../../../src/pi3-frontend/generics/GSelect/GSelectPlaceholder.jsx';
import {GSelect} from '../../../src/pi3-frontend/generics/GSelect/GSelect.jsx';
import {GTableCell} from '../../../src/pi3-frontend/generics/GTable/GTableCell.jsx';
import {GTableHeaderCell} from '../../../src/pi3-frontend/generics/GTable/GTableHeaderCell.jsx';
import {GTableHeader} from '../../../src/pi3-frontend/generics/GTable/GTableHeader.jsx';
import {GTableRow} from '../../../src/pi3-frontend/generics/GTable/GTableRow.jsx';
import {GTable} from '../../../src/pi3-frontend/generics/GTable/GTable.jsx';
import {IconClose} from '../../../src/pi3-frontend/icons/IconClose.jsx';
import {objectFilter} from '../../../src/utilities/Object.js';
import {useAPI} from '../../../src/pi3-frontend/API.js';
import {useEffect} from 'react';
import {useRouter} from '../../../src/pi3-frontend/Router.js';
import {useState} from 'react';

export const AdminCompaniesPage = function (props) {

  const api = useAPI();
  const router = useRouter();

  const [companiesList, setCompaniesList] = useState([]);

  const [search, setSearch] = useState(router.getCurrentRoute().query.search ?? '');

  const possibleFilters = [
    {
      label: 'Up for renewal',
      param: 'upForRenewal',
    },
    {
      label: 'Enterprise plan',
      param: 'enterprisePlan',
    },
  ];

  const [activeFilters, setActiveFilters] = useState([
    router.getCurrentRoute().query.upForRenewal ? 'upForRenewal' : '',
    router.getCurrentRoute().query.enterprisePlan ? 'enterprisePlan' : '',
  ].filter(v => !!v));

  const [apiCompaniesListParams, setAPICompaniesListParams] = useState({
    fields: [
      'id',
      'name',
      'plans',
    ].join(','),
    search: search,
    'criteria[upForRenewal]': activeFilters.includes('upForRenewal') ? true : undefined,
    'criteria[enterprisePlan]': activeFilters.includes('enterprisePlan') ? true : undefined,
  });

  const now = DateTime.utc();

  useEffect(() => {
    router.replace({query: {search: search ? search : undefined}});
    const newAPICompaniesListParams = {
      ...apiCompaniesListParams,
      search: search,
    };
    if (JSON.stringify(newAPICompaniesListParams) == JSON.stringify(apiCompaniesListParams))
      return;
    setAPICompaniesListParams(newAPICompaniesListParams);
  }, [search]);

  useEffect(() => {
    router.replace({query: {
      upForRenewal: activeFilters.includes('upForRenewal') ? true : undefined,
      enterprisePlan: activeFilters.includes('enterprisePlan') ? true : undefined,
    }});
    const newAPICompaniesListParams = {
      ...apiCompaniesListParams,
      'criteria[upForRenewal]': activeFilters.includes('upForRenewal') ? true : undefined,
      'criteria[enterprisePlan]': activeFilters.includes('enterprisePlan') ? true : undefined,
    };
    if (JSON.stringify(newAPICompaniesListParams) == JSON.stringify(apiCompaniesListParams))
      return;
    setAPICompaniesListParams(newAPICompaniesListParams);
  }, [activeFilters]);

  useEffect(() => {
    if (props?.ssr)
      return;
    (async () => {
      setCompaniesList(await api.get({
        url: '/api/v3/admin/companies?'
          + new URLSearchParams(objectFilter(apiCompaniesListParams, (k, v) => v !== undefined)),
      }));
    })();
  }, [apiCompaniesListParams]);

  return (<>

    <GBox style={{padding: 'var(--spacing-7)'}}>

      <GHeading>Company list</GHeading>

      <hr
        style={{
          border: 'none',
          borderTop: 'var(--border-radius-base) var(--color-pi3-stroke-grey) dotted',
        }}
      />

      <GFlex
        style={{
          justifyContent: 'flex-start',
          columnGap: 'var(--spacing-2)',
          marginTop: 'var(--spacing-7)',
        }}
      >
        <GFlexItem style={{flex: 'none', width: 'var(--spacing-44)'}}>
          <GSelect onChange={(val) => { setActiveFilters([...activeFilters, val]); }}>
            <GSelectPlaceholder>add filter</GSelectPlaceholder>
            {possibleFilters.filter(v => !activeFilters.includes(v.param)).map(filter =>
              <GSelectOption key={filter.param} value={filter.param}>
                {filter.label}
              </GSelectOption>
            )}
          </GSelect>
        </GFlexItem>
        <GFlexItem style={{width: 'auto'}}>
          <GInputSearch
            value={search}
            placeholder="Search for company..."
            onChange={(val) => { setSearch(val); }}
          />
        </GFlexItem>
      </GFlex>

      <GFlex
        style={{
          justifyContent: 'flex-start',
          columnGap: 'var(--spacing-2)',
          marginTop: activeFilters.length > 0 ? 'var(--spacing-2)' : '0',
        }}
      >
        {activeFilters.map(filterParam =>
          <GFlexItem
            key={filterParam}
            style={{flex: 'none', width: 'auto'}}
          >
            <GButton
              onClick={(e) => { setActiveFilters(activeFilters.filter(v => v != filterParam)); }}
              variant='active'
              style={{
                width: 'auto',
                fontSize: 'var(--text-xs-fs)',
                lineHeight: 'var(--text-xs-lh)',
                textTransform: 'uppercase',
              }}
            >
              {possibleFilters.filter(v => v.param == filterParam)?.[0]?.label}
              <IconClose
                style={{
                  marginLeft: 'var(--spacing-4)',
                  fontSize: 'var(--text-xs-fs)',
                  lineHeight: 'var(--text-xs-lh)',
                }}
              />
            </GButton>
          </GFlexItem>
        )}
      </GFlex>

      <GBox
        style={{
          marginTop: 'var(--spacing-7)',
        }}
      >
        <GTable>
          <GTableHeader>
            <GTableHeaderCell>
              Company
            </GTableHeaderCell>
            <GTableHeaderCell>
              Plans
            </GTableHeaderCell>
            <GTableHeaderCell>
              Manager Contacts
            </GTableHeaderCell>
            <GTableHeaderCell>
            </GTableHeaderCell>
          </GTableHeader>
          {companiesList.map(company =>
            <GTableRow key={company.id}>
              <GTableCell>
                {company.name}
              </GTableCell>
              <GTableCell>
                <GList>
                  {company.plans.map(plan =>
                    <GListItem key={plan.id}>
                      <strong>{plan.name}</strong>
                      &nbsp;({plan.actualUserCount}/{plan.totalSeats} enrolled)
                      &nbsp;{DateTime.fromISO(plan.expiresAt) < now ? 'Expired' : 'Active'}
                    </GListItem>
                  )}
                </GList>
              </GTableCell>
              <GTableCell>
                {company.plans.map(plan =>
                  plan.managers.map(manager =>
                    <GListItem key={manager.id}>
                      <strong>{manager.name}</strong><br />
                      {manager.email}
                    </GListItem>
                  )
                )}
              </GTableCell>
              <GTableCell>
                <GAlign variant='row right' style={{columnGap: 'var(--spacing-2)'}}>
                  <GRouterLink
                    to={{path: '/p/company-management', query: {action: 'viewCompany', id: company.id}}}
                  >
                    View
                  </GRouterLink>
                  <a href="#" onClick={(e) => { e.preventDefault(); createCompanyDeleteDialog({id: company.id}); }}>Delete</a>
                </GAlign>
              </GTableCell>
            </GTableRow>
          )}
        </GTable>
      </GBox>

    </GBox>

  </>);

};
