'use strict';

import React from 'react';
import { Tooltip } from 'react-tooltip';

import { getRandomHex } from './helpers';

const height = '16px'; // module line height

class LectureCompletionGraph extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {

    let data = this.props.data;

    // hack to re-generate Tooltip every time we minimize/maximize, otherwise it doesn't add events to any changed/added elements
    let tooltipId = getRandomHex();

    let elements = data.map(row => {

      // row3 is 'not started' = total users minus users passed(row[1]) minus users in progress(row[2])
      let row3 = this.props.actualUserCount - row[1] - row[2];
      // console.log('these are calculations', { row1: row[1], row2: row[2], row3: row3 });

      // determine highest value, which will be 100% width of the graph
      let maxValue =  row[1] + row[2] + row3;

      // calculate column widths relative to maxValue
      // let width1 = Math.round(row[1] / maxValue * 100); // % users completed
      // let width2 = Math.round(row[2] / maxValue * 100); // % users in progress
      // let width3 = Math.round(row3 / maxValue * 100); // % users not started
      let width1 = row[1] / maxValue * 100; // % users completed
      let width2 = row[2] / maxValue * 100; // % users in progress
      let width3 = row3 / maxValue * 100; // % users not started

      // percentages could all get rounded up one higher by Math.round
      if ((width1 + width2 + width3) >= 101) {
        width3 -= 1;
        // if ((width1 + width2 + width3) >= 102) {
        //   width2 -= 1;
        // }
      }


      return (
        <div key={row[0]} className='lectureCompletionGraphItem'>
          <a data-tooltip-id={tooltipId} data-tooltip-content={`${row[1]} learners completed`} data-tooltip-place="top">
            <div style={{ background: '#76003b', height, width: `${width1}%`, display: 'inline-block' }}>
            </div>
          </a>
          <a data-tooltip-id={tooltipId} data-tooltip-content={`${row[2]} learners in progress`} data-tooltip-place="top">
            <div style={{ background: '#e5aaab', height, width: `${width2}%`, display: 'inline-block' }}>
            </div>
          </a>
          <a data-tooltip-id={tooltipId} data-tooltip-content={`${row3} learners not started`} data-tooltip-place="top">
            <div style={{ background: '#d9d9d9', height, width: `${width3}%`, display: 'inline-block' }}>
            </div>
          </a>

          <br />
          <span>{row[0]}</span>
          <br />
        </div>
      );
    });


    if (!elements) {
      console.log('nesto se razdrkalo sa elements');
    }


    return (
      // https://react-tooltip.netlify.com/
      <div className='lectureCompletionGraph'>
        {elements}
        <Tooltip id={tooltipId} place="top" />
      </div>
    );
  }
}

// class LectureCompletionGraphItem extends React.Component {
// }

export default LectureCompletionGraph;