
'use strict';

import React from 'react';
import ReactDOM from 'react-dom';


class CourseBlockYouTube extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    let block = this.props.block;
    return (
      <div className="pi3courseVideo" key={block.id}>
        <iframe
          src={`https://www.youtube.com/embed/${block.youtubeId}`}
          aria-label={this.props.alt}
          frameBorder="0"
          allowFullScreen>
        </iframe>
      </div>
    );
  }
}

export default CourseBlockYouTube;