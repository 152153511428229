
import 'cross-fetch/polyfill';

async function submitCompanyOnboarding2(formData) {
  try {
    console.log(`Submitting company onboarding form (v2)...`, formData);
    let url = `/company-onboarding/v2`;
    let result = await fetch(url, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(formData),
    });
    if (!result.ok) {
      console.error('Error submitting company onboarding form (v2).', formData);
      throw result;
    }
    let json = await result.json();
    return json;
  } catch(e) {
    console.error('Error submitting company onboarding form (v2).', { formData, e });
    throw e;
  }
}

async function getIndividualProducts() { // deprecated
  try {
    // console.log(`Fetching individual products...`);
    let url = `/new-purchase-flow-individual-products`;
    let data = await fetch(url, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'GET',
    });

    if (!data.ok) {
      // TODO add 'unauthorized' recovery here if necessary?
      console.error('Error fetching individual products.', data);
      return;
    }
    let json = await data.json();
    // console.log('Fetched current individual products.', json.length);
    return json;


  } catch(e) {
    console.error('Error fetching individual products.', e);
    throw e;
  }
}

async function submitIndividualOnboarding(formData) { 
  try {
    console.log(`Submitting onboarding form...`, formData);
    let url = `/new-purchase-flow`;
    let result = await fetch(url, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(formData),
    });
    if (!result.ok) {
      console.error('Error submitting onboarding form.', formData);
      throw result;
    }
    let json = await result.json();
    return json;
  } catch(e) {
    console.error('Error submitting individual onboarding form.', { formData, e });
    throw e;
  }
}

async function validateNewUserEmail(email) { 
  try {
    console.log(`Validating new user email...`, email);
    let url = `/new-purchase-flow-validate-email`;

    let formData = { email };

    let result = await fetch(url, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(formData),
    });
    if (!result.ok) {
      console.error('Error validating new user email.', formData);
      throw result;
    }
    let json = await result.json();
    return json;
  } catch(e) {
    console.error('Error validating new user email.', { email, e });
    throw e;
  }
}

async function validateCouponCode(couponCode) {
  try {
    console.log(`Validating couponCode...`, couponCode);
    let url = `/new-purchase-flow-validate-coupon`;

    let formData = { couponCode };

    let result = await fetch(url, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(formData),
    });
    if (!result.ok) {
      console.error('Error validating couponCode.', formData);
      throw result;
    }
    let json = await result.json();
    return json;
  } catch(e) {
    console.error('Error validating couponCode.', { couponCode, e });
    throw e;
  }
}

async function getCheckoutSession(stripeCheckoutSessionId) {
  try {
    console.log(`Fetching Stripe Checkout Session...`, stripeCheckoutSessionId);
    let url = `/new-purchase-flow-checkout-session/${stripeCheckoutSessionId}`;
    let result = await fetch(url, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'GET',
    });
    if (!result.ok) {
      console.error('Error fetching Stripe Checkout Session .', result);
      throw result;
    }
    let json = await result.json();
    return json;
  }
  catch(e) {
    console.error('Error fetching Stripe Checkout Session.', { e });
    throw e;
  }
}

async function getCompanyProducts2() {
  try {
    // console.log(`Fetching current products...`);
    let url = `/company-products/v2`;
    let data = await fetch(url, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'GET',
    });
    if (!data.ok) {
      // TODO add 'unauthorized' recovery here if necessary?
      console.error('Error fetching Company Products.', data);
      return;
    }
    let json = await data.json();
    // console.log('Fetched current Company Products.', json.length);
    return json;
  } catch(e) {
    console.error('Error fetching Company Products...', e);
    throw e;
  }
}



export {
  // company-onboarding2
  getCompanyProducts2,
  submitCompanyOnboarding2,

  // new-purchase-flow
  getIndividualProducts,
  validateNewUserEmail,
  validateCouponCode,
  submitIndividualOnboarding,
  getCheckoutSession,
};