
import {isArray} from '../../src/utilities/Type.js';
import {isFunction} from '../../src/utilities/Type.js';
import {isObject} from '../../src/utilities/Type.js';

export const valueListToArray = function (val) {
  if (arguments.length > 1) {
    const result = [];
    for (const v of arguments)
      for (const vr of valueListToArray(v))
        result.push(vr);
    return result;
  }
  if ((val ?? null) === null)
    return [];
  if (isArray(val)) {
    const result = [];
    for (const v of val)
      for (const vr of valueListToArray(v))
        result.push(vr);
    return result;
  }
  if (isObject(val)) {
    const result = [];
    for (let [k, v] of Object.entries(val)) {
      while (isFunction(v))
        v = v();
      if (!v)
        continue;
      result.push(k);
    }
    return result;
  }
  if (isFunction(val))
    return valueListToArray(val());
  return String(val).trim().split(/[ \t\r\n]+/);
};
