'use strict';

import React from 'react';

import enums from './enums';

import { getCourseProgress } from './ajax';

import { getTypeIcon, getStatusIcon, MenuCourseProgress } from './MenuTools';

import PurchaseCartIcon from './PurchaseCartIcon';

class LegacyMenuVintageNew extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false, // burger state
      data: this.props.data, // unused for now
    };
    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }
  handleClickOutside(event) {
    if (this.state.isOpen) {
      console.log('[LegacyMenuVintageNew] Handling clickOutside...');
      if (this.wrapperRef && this.wrapperRef.current && !this.wrapperRef.current.contains(event.target)) {
        this.setState({ isOpen: false });
      }
    }
  }
  async componentDidMount() {
    try {
      document.addEventListener('mousedown', this.handleClickOutside);
    }
    catch(e) {
      console.error('[LegacyMenuVintageNew] Error in componentDidMount().', e);
    }
  }
  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }
  render() {


    let data = this.state.data;

    let renderedBurgerItems = data.items.map(i => {
      return (
        <a href={i.link} key={i.label}>
          <div className={getItemClass(i.link, this.state.url)}>
            {i.label}
          </div>
        </a>
      );
    });

    let renderedMenuItems = data.items.map(i => {
      return (
        <li key={i.label}>
          <a href={i.link}>{i.label}</a>
        </li>
      );
    });

    let burgerSliderClassName = this.state.isOpen ? 'slider visible' : 'slider';

    return (
      <div>
        <header className="pi3topMenuWhite">

          {/* burger */}
          <div className='burger'>
            {
              this.state.isOpen &&
              <div className="icon-burger-white-close" onClick={() => this.setState({ isOpen: false })}></div>
            }
            {
              !this.state.isOpen &&
              <div className="icon-burger-white" onClick={() => this.setState({ isOpen: true })}></div>
            }
            <div className={burgerSliderClassName}>
              <div className="pi3menuPop" ref={this.wrapperRef}>
                {renderedBurgerItems}
              </div>
            </div>
          </div>

          {/* logo */}
          <a href="/" className="logoWhite" aria-label="Product Institute">
            <img
              src="/static/images/productinstitute-logo-color3.svg"
              alt="Product Institute"
            />
          </a> 
          <a href="/" className="logoWhiteMobile" aria-label="Product Institute">
            <img 
              src="/static/images/productinstitute-logo-small-color.svg"
              alt="Product Institute"
            />
          </a> 

          {/* top navigation */}
          <nav>
            <ul>
              {renderedMenuItems}
            </ul>
          </nav>

        </header>

        <div className="pi3below"></div> {/* create space for elements below */}

      </div>
    );
  }
}



function getItemClass(item) {
  if (item && item.isActive) {
    return 'pi3menuItem active';
  }
  return 'pi3menuItem';
}

class Menu extends React.Component {
  constructor(props) {
    super(props);
    // console.log('[Menu] Constructing...');
    this.state = { 
      isOpen: false,
      data: this.props.data,
      cart: null,
    };
    if (typeof window !== 'undefined') {
      this.state.cart = window.cart;
      console.log('[Menu] Global cart found.');
    }
    if (!props.data) {
      console.error('[Menu] Menu data not set through props.');
    }
    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }
  handleClickOutside(event) {
    if (this.state.isOpen) {
      console.log('[Menu] Handling clickOutside...');
      if (this.wrapperRef && this.wrapperRef.current && !this.wrapperRef.current.contains(event.target)) {
        this.setState({ isOpen: false });
      }
    }
  }
  async componentDidMount() {
    try {
      document.addEventListener('mousedown', this.handleClickOutside);

      console.log('[Menu] Loading course status...');
      if (!this.state.data || !this.state.data.courseItems) return;
      let progress = await getCourseProgress(this.state.data.courseItems.id);
      // console.log('fetched progress?', progressItems);

      // set lesson completion states
      let data = this.state.data;
      for (let section of data.courseItems.sections) {
        for (let lesson of section.lessons) {
          let l = progress.lessons.find(i => i.lessonId === lesson.id);
          if (l.isCompleted) {
            lesson.status = enums.CourseLesson.Status.Read;
          }
          else if (l.isInProgress) {
            lesson.status = enums.CourseLesson.Status.Reading;
          }
        }
      }
      this.setState({ data, progress });

      // scoll into position
      const element = document.getElementById('mainMenuActive');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }

    }
    catch(e) {
      console.error('[Menu] Error in componentDidMount().', e);
    }
  }
  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  render() {

    let data = this.state.data;
    let progress = this.state.progress;

    // if logged out, show 'logged out' / legacy menu
    if (data.isAuthenticated === false) {
      // console.log(`[Menu] Rendering legacy/logged-out menu...`);
      return <LegacyMenuVintageNew data={this.props.data} />;
    }

    let renderedBurgerItems = data.items.map(i => {
      return (
        <a href={i.link} key={i.label}>
          <div className={getItemClass(i.link, this.state.url)}>
            {i.label}
          </div>
        </a>
      );
    });

    let courseContainer = '';
    let courseProgressContainer = '';

    if (data.courseItems) { 
      // console.log('[Menu] We have data.courseItems, loading course data into SideMenu.');
      let courseItems = [];
      // create section items
      for (let s = 0; s < data.courseItems.sections.length; s++) {
        let section = data.courseItems.sections[s];
        let lessonItems = [];
        // create lesson items
        for (let l = 0; l < section.lessons.length; l++) {
          let lesson = section.lessons[l];

          let href = `/course/${data.courseItems.id}/lesson/${lesson.id}`;
          if (lesson.type === enums.CourseLesson.Type.Quiz) {
            href = `/course/${data.courseItems.id}/quiz/${lesson.id}`;
          }
          else if (lesson.type === enums.CourseLesson.Type.QuizPlus) {
            href = `/course/${data.courseItems.id}/quiz-plus/${lesson.id}`;
          }
          else if (lesson.type === enums.CourseLesson.Type.Chatflow) {
            href = `/course/${data.courseItems.id}/chatflow/${lesson.id}`;
          }

          if (this.props.adminMode) {
            // lesson editor links, rather than 'reader' links
            href = `/admin/lesson/${lesson.id}/editor`;
            if (lesson.type === enums.CourseLesson.Type.Quiz) {
              href = `/admin/quiz/${lesson.id}/editor`;
            }
            else if (lesson.type === enums.CourseLesson.Type.QuizPlus) {
              href = `/admin/quiz-deluxe/${lesson.id}/editor`;
            }
          }

          let lessonClassName = 'pi3menuCourseLesson';
          let statusIcon = getStatusIcon(lesson.status);
          if (lesson.isActive) {
            lessonClassName = 'pi3menuCourseLesson active';
            if (lesson.status === enums.CourseLesson.Status.Unread) {
              statusIcon = getStatusIcon(enums.CourseLesson.Status.Reading);
            }
          }

          if (lesson.isActive) {
            lessonItems.push(
              <a href={href} key={l}>
                <div className={lessonClassName} id='mainMenuActive'>
                  <div className="left">
                    {statusIcon}
                  </div>
                  <div className="separator"></div>
                  <div className="middle">
                    {getTypeIcon(lesson.type)}
                  </div>
                  <div className="right">
                    {lesson.name}
                  </div>
                </div>
              </a>
            );
          }
          else {
            lessonItems.push(
              <a href={href} key={l}>
                <div className={lessonClassName}>
                  <div className="left">
                    {statusIcon}
                  </div>
                  <div className="separator"></div>
                  <div className="middle">
                    {getTypeIcon(lesson.type)}
                  </div>
                  <div className="right">
                    {lesson.name}
                  </div>
                </div>
              </a>
            );
          }

        }
        let sectionItem = (
          <div key={section.id}>
            <div className='pi3menuCourseHeader'>
              {/* <span>Unit {s+1}</span>
              <br /> */}
              <span>{section.name}</span>
            </div>
            {lessonItems}
          </div>
        );
        courseItems.push(sectionItem);
      }
      courseContainer = (
        <div className='pi3menuCourseContainer'>
          {courseItems}
        </div>
      );
      if (progress) {
        courseProgressContainer = (
          <MenuCourseProgress progress={progress} course={data.courseItems} />
        );
      }
    }

    let burgerSliderClassName = this.state.isOpen ? 'pi3topMenuBurgerSlider pi3topMenuBurgerSliderVisible' : 'pi3topMenuBurgerSlider';

    return (
      <div>
        <header className="pi3topMenu" href="#topmenu">
          {/* logo */}
          <TopMenuLogo />

          {/* burger */}
          <div className='pi3topMenuBurger'>
            {
              this.state.isOpen &&
              <div className="icon-burger-close" onClick={() => this.setState({ isOpen: false })}></div>
            }
            {
              !this.state.isOpen &&
              <div className="icon-burger" onClick={() => this.setState({ isOpen: true })}></div>
            }
            <div className={burgerSliderClassName}>
              <div className="pi3menuPop" ref={this.wrapperRef}>
                {renderedBurgerItems}
                {courseContainer}
                {courseProgressContainer}
              </div>
            </div>
          </div>

          {/* top navigation */}
          <nav>
            <ul>
              <li><PurchaseCartIcon cart={this.state.cart} /></li>
              <li><a href="/help">Help</a></li>
              <li><a href="/logout">Logout</a></li>
              <li>
                <a href="/profile" aria-label="Profile">
                  <svg viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_2870_6193)">
                      <path d="M13.0004 23.5618C9.78531 23.5618 6.57019 23.5618 3.35507 23.5618C1.37672 23.5618 -0.056128 22.0913 0.00168881 20.123C0.106011 16.5773 2.36464 13.469 5.69288 12.275C5.9254 12.192 6.0536 12.2222 6.22077 12.4132C9.83684 16.5446 16.1615 16.5433 19.7826 12.4107C19.9523 12.2171 20.083 12.197 20.3117 12.2775C23.6463 13.4539 25.9476 16.6477 25.9992 20.1858C26.0268 22.1126 24.604 23.5606 22.6772 23.5618C19.452 23.5631 16.2256 23.5618 13.0004 23.5618Z" fill="white"/>
                      <path d="M6.9043 6.52238C6.90681 3.16146 9.64054 0.434011 13.0077 0.436525C16.3687 0.439039 19.0999 3.17654 19.0961 6.53872C19.0923 9.89712 16.3511 12.6321 12.9914 12.6296C9.63426 12.6271 6.90178 9.88581 6.9043 6.52238Z" fill="white"/>
                    </g>
                    <defs>
                      <clipPath id="clip0_2870_6193">
                        <rect width="26" height="23.1267" fill="white" transform="translate(0 0.436523)"/>
                      </clipPath>
                    </defs>
                  </svg>
                  <span className='pi3topMenuLogoName'>
                    {data.user.name}
                  </span>
                </a>
              </li>
            </ul>
          </nav>
        </header>

        <div className="pi3below"></div> {/* create space for elements below */}

      </div>

    );

 
  }
}

export default Menu;







function TopMenuLogo() { 
  return (
    <a href="/" className="pi3topMenuLogo" aria-label="Home">
      <svg width="88" height="58" viewBox="0 0 88 58" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="88" height="58" />
        <g clipPath="url(#clip0_2601_15266)">
          <path d="M46.5991 26.5039L37.7009 31.2039L38.2596 45.2825L54.2675 30.0753C53.7925 30.0247 53.3279 29.9043 52.8891 29.7182C52.0802 29.3825 46.5991 26.5039 46.5991 26.5039Z" fill="white"/>
          <path d="M36.8195 45.9359C36.8195 45.9359 35.626 15.6395 35.568 14.8217C35.5614 14.1886 35.334 13.5769 34.9239 13.0893C34.5138 12.6016 33.9458 12.2676 33.3153 12.1431L27.5731 11.0467C26.7554 10.9109 25.9161 11.0941 25.2333 11.5574C24.1161 12.2181 23.8114 12.9109 24.1052 13.8252C24.399 14.7395 32.9127 44.3502 32.9127 44.3502C33.1702 45.3431 33.5402 45.9288 34.7953 46.6145C36.0504 47.3002 36.8847 47.0359 36.8195 45.9359Z" fill="white"/>
          <path d="M37.2402 20.0714L53.114 28.2143C53.114 28.2143 55.1562 29.4179 56.5601 27.8571C58.2686 26.3214 62.9843 21.7679 62.9843 21.7679C63.6808 21.0857 64.2067 20.0893 63.9202 19.2857C63.8152 18.8772 63.5903 18.5082 63.2735 18.2249C62.9567 17.9416 62.5621 17.7565 62.1391 17.6929L36.4131 12.75C36.8155 13.4722 37.0333 14.28 37.048 15.1036L37.2402 20.0714Z" fill="white"/>
        </g>
        <defs>
          <clipPath id="clip0_2601_15266">
            <rect width="40" height="36" fill="white" transform="translate(24 11)"/>
          </clipPath>
        </defs>
      </svg>
    </a>
  );
}



