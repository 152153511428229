
import enums from '../../src/pi3-frontend/enums.js';
import React from 'react';
import {postLearnerActivity} from '../../src/pi3-frontend/ajax.js';
import {useFirstRender} from '../../src/utilities/React.js';

// Access token used to prevent cross-site scripting attacks.
//
// @see https://developer.mozilla.org/en-US/docs/Web/API/Window/postMessage#security_concerns
const iFrameToken = (() => {
  const bits = 144;
  const bytes = Math.ceil(bits / Math.log2(36));
  const randomBytes = new Uint8Array(bytes);
  // `window.crypto` is a native browser function that needs to be accessed
  // without `window` when used from NodeJS.
  crypto.getRandomValues(randomBytes);
  // `window.btoa` is a native browser function that needs to be accessed
  // without `window` when used from NodeJS.
  return btoa([...randomBytes].reduce((c, b) => c + String.fromCharCode(b), ''))
    .replace(/[\+\/\=]/g, '').toLowerCase().substring(0, bytes)
  ;
})();

export const LearnerChatflow = function (props) {

  /*
      window.addEventListener("message", (e) => {
      console.log("Parent got " + JSON.stringify(e.data) + " from " + e.origin);
    });
  /**/

  useFirstRender(async () => {
    try {
      console.log('[LearnerLesson] Posting lesson view...');
      await postLearnerActivity(enums.UserActivity.Type.LessonView, props.lesson.id);
    } catch (err) {
      console.error('[LearnerLesson] Error posting lesson view', err);
    }
    window.addEventListener("message", async (e) => {
      try {
        const msg = JSON.parse(e.data);
        if (msg?.token != iFrameToken)
          return;
        //console.log("Parent got", msg, "from", e.origin);
        try {
          console.log('[LearnerLesson] Posting lesson complete...');
          await postLearnerActivity(enums.UserActivity.Type.LessonComplete, props.lesson.id);
        } catch (err) {
          console.error('[LearnerLesson] Error posting lesson complete', err);
        }
      } catch (err) {}
    });
  });

  return (<>
    <iframe
      className="tw:border-0 tw:w-full tw:h-full"
      src={`${__CHATFLOW_API_URL__}/chatflow/${encodeURIComponent(props.lesson.chatflowId)}?-iframe-token=${encodeURIComponent(iFrameToken)}`}
    />
  </>);

};
