
import React from 'react';

export const GBox = function (props) {

  return (<>
    <div
      style={{
        boxSizing: 'border-box',
        position: 'relative',
        width: '100%',
        height: '100%',
        ...props?.style,
      }}
    >
      {props?.children}
    </div>
  </>);

};
