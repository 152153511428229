'use strict';

import React from 'react';

import validation from './validation';

import { shallowCopy } from './helpers';
import { submitContactFormPublic } from './ajax';


class ContactUsPublic extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        name: '', // Full Name e.g. John Smith
        email: '', // Email Address name@company.com
        companyName: '', // Company e.g. Acme
        location: '', // Location e.g. San Francisco, CA
        message: '', // Message What would you like to discuss or what is your question?
      },
      validate: false,
      tree: null,
      state: 'new',
    };
    this.merge = this.merge.bind(this);
    this.validate = this.validate.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }
  merge(newData) {
    let form = shallowCopy(this.state.form, newData);
    this.setState({
      form,
      tree: this.validate(null, form)
    });
  }
  validate(attribute, newData) {
    // console.log('what are we merging?', { attribute, newData });
    let data = newData ? newData : this.state.form;

    // if attribute is provided, return empty string or CSS class to add to invalid field
    if (attribute) {
      if (this.state.validate) {
        // check whether attribute inside validation tree is valid, if not return 'error' CSS class
        if (this.state.tree && this.state.tree[attribute] && this.state.tree[attribute].length > 0) {
          return 'error';
        }
        return '';
      } else {
        return '';
      }
    } else {
      // generate validation tree
      let tree = {
        name: validation.validateString(data.name) ? null : 'Name is required.',
        email: validation.validateEmail(data.email) ? null : 'E-mail address is required.',
        message: validation.validateString(data.message) ? null : 'Message is required.',
      };
      return tree;
    }
  }

  async componentDidMount() {
    try {
      console.log('[ContactUs] Handling componentDidMount()...');

      // start timer here to periodically refetch user?
    } catch(e) {
      console.error('[ContactUs] Error handling componentDidMount().', e);
      this.setState({ loggedIn: false });
    }
  }
  async onSubmit() {
    try {
      // attempt validation
      // console.log('submitting...');
      let tree = this.validate(null, this.state.form);
      // console.log('we have a validation tree', tree);
      let isHealthy = validation.isTreeHealthy(tree);
      this.setState({
        tree,
        validate: true
      });
      if (isHealthy) {
        let data = {
          name: this.state.form.name,
          email: this.state.form.email,
          companyName: this.state.form.companyName,
          location: this.state.form.location,
          message: this.state.form.message,
        };
        // console.log('we have a valid form.', data);
        this.setState({ state: 'sending' });

        await submitContactFormPublic(data);
        // console.log('done with submission, got response from server.', result);
        // if (window) {
        //   window.scrollTo(0, 0);
        // }
        this.setState({ state: 'sent' });
      } else {
        // console.log('we have an invalid form', this.state.form);
      }
    } catch(e) {
      console.error(`[ContactUs] Error handling onSubmit().`, e);
    }
  }

  render() {

    if (this.state.state === 'sending' || this.state.state === 'sent') {
      return (
        <div className='pi3form'>

          <div className="formRow">
            <label htmlFor="input-fullname">Full Name</label>
            <input type='text' disabled id='input-fullname' name='name' value={this.state.form.name} />
          </div>

          <div className="formRow">
            <label htmlFor="input-email">Email Address</label>
            <input type='email' disabled id='input-email' name='email' value={this.state.form.email} />
          </div>

          <div className="formRow">
            <label htmlFor="input-company">Company</label>
            <input type='text' disabled id='input-company' name='companyName' value={this.state.form.companyName} />
          </div>

          <div className="formRow">
            <label htmlFor="input-location">Location</label>
            <input type='text' disabled id='input-location' name='location' value={this.state.form.location} />
          </div>

          <div className="formRow">
            <label htmlFor="input-message">Message</label>
            <textarea disabled id='input-message' name='message' value={this.state.form.message} />
          </div>

          {
            this.state.state === 'sending' &&
            <div className="formRow submitRow">
              <button className='pi3button disabled'>Sending...</button>
            </div>
          }

          {
            this.state.state === 'sent' &&
            <div>
              <div className="formRow submitRow">
                <button className='pi3button disabled'>Sent.</button>
              </div>
              <p>
                <br />
                Thank you for reaching out! We will be in touch shortly.
              </p>
            </div>
          }

        </div>

      );
    }

    return (

      <div className='pi3form'>

        <div className="formRow">
          <label htmlFor="input-fullname">Full Name</label>
          <input type='text' className={` ${this.validate('name')}`} id='input-fullname' name='name' value={this.state.form.name} onChange={e => this.merge({ name: e.target.value })} placeholder='e.g. John Smith' maxLength={254} />
        </div>
        <div className="formRow">
          <label htmlFor="input-email">Email Address</label>
          <input type='email' className={` ${this.validate('email')}`} id='input-email' name='email' value={this.state.form.email} onChange={e => this.merge({ email: e.target.value })} placeholder='e.g. name@company.com' maxLength={254} />
        </div>
        <div className="formRow">
          <label htmlFor="input-company">Company</label>
          <input type='text' className='' id='input-company' name='companyName' value={this.state.form.companyName} onChange={e => this.merge({ companyName: e.target.value })} placeholder='e.g. Acme' maxLength={254} />
        </div>
        <div className="formRow">
          <label htmlFor="input-location">Location</label>
          <input type='text' className='' id='input-location' name='location' value={this.state.form.location} onChange={e => this.merge({ location: e.target.value })} placeholder='e.g. San Francisco, CA' maxLength={254} />
        </div>
        <div className="formRow">
          <label htmlFor="input-message">Message</label>
          <textarea className={` ${this.validate('message')}`} id='input-message' name='message' value={this.state.form.message} onChange={e => this.merge({ message: e.target.value })} placeholder='What would you like to discuss or what is your question?' />
        </div>
        <div className="formRow submitRow">
          <button className='pi3button' onClick={this.onSubmit}>Submit</button>
          
        </div>

      </div>

    );
  }
}

// AIzaSyDr7y-d1Rf2htgicYUThErF1X4LdHX_gsg
// https://mapstyle.withgoogle.com/
// https://console.cloud.google.com/apis/credentials/key/19c9ad7c-150a-4404-a73e-0b70d5664640?project=product-institute
// https://developers.google.com/maps/documentation/embed/usage-and-billing </main>/
// https://developers.google.com/maps/documentation/embed/start
// https://www.labnol.org/internet/embed-responsive-google-maps/28333/


export default ContactUsPublic;

