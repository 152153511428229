
import React from 'react';

export const GTableCell = function (props) {
  return (<>
    <td style={{verticalAlign: 'middle', overflow: 'hidden', padding: 'var(--spacing-1_5)'}}>
      {props.children}
    </td>
  </>);
};
