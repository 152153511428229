
import React from 'react';
import {GSelectPlaceholder} from '../../../../src/pi3-frontend/generics/GSelect/GSelectPlaceholder.jsx';

export const GSelect = function (props) {

  return (<>
    <select
      value=""
      onChange={(e) => { props?.onChange?.(e.target.value); }}
      style={{
        boxSizing: 'border-box',
        position: 'relative',
        width: '100%',
        height: '100%',
        fontFamily: 'Nunito Sans',
      }}
    >
      {props?.children?.filter?.(c => c.type === GSelectPlaceholder)}
      {props?.children?.filter?.(c => c.type !== GSelectPlaceholder)}
    </select>
  </>);

};
