
import React from 'react';
import {GTableHeader} from '../../../../src/pi3-frontend/generics/GTable/GTableHeader.jsx';
import {toAutoArray} from '../../../../src/utilities/Type.js';

export const GTable = function (props) {

  const children = toAutoArray(props.children);

  return (<>
    <table style={{display: 'table', width: '100%', borderCollapse: 'collapse', textIndent: 0}}>
      {children.filter(c => c.type === GTableHeader)}
      <tbody style={{position: 'relative'}}>
        {children.filter(c => c.type !== GTableHeader)}
      </tbody>
    </table>
  </>);

};
