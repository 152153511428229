
// return purchase from existing user
import React from 'react';

import { loadStripe } from '@stripe/stripe-js';
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js';

import { getUserInfo, getProductsV4, purchaseReturn } from './ajax';
import { getQueryAttribute, toHumanArray } from './helpers';

import Image from './Image';
import LoadingCenter from './LoadingCenter';

// eslint-disable-next-line no-undef
const STRIPE_PUBKEY = __STRIPE_PUBLISHABLE_KEY__;
const stripePromise = loadStripe(STRIPE_PUBKEY);


class PurchaseReturn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stage: 'loading',
      cart: null,
      products: [],
      stripeCheckoutSessionId: null,
    };
    // load cart from window if available
    if (typeof window !== 'undefined') {
      this.state.cart = window.cart;
      console.log('[PurchaseReturn] Global cart found.');
    }
  }
  async componentDidMount() {
    try {
      // fetch user info
      let user = await getUserInfo();
      console.log('[PurchaseReturn] Fetched user info.', user);

      // fetch cart
      if (!this.state.cart) {
        console.error('[PurchaseReturn] Cart not found.');
        return;
      }
      let cartData = this.state.cart.getCart();
      console.log('[PurchaseReturn] Fetched cart data.', cartData);

      // fetch products
      let products = await getProductsV4();
      console.log(`[PurchaseReturn] Fetched products.`, products);

      // check if 'complete' state
      let stage = getQueryAttribute(window.location.search, 's');
      if (stage === 'complete') {

        // empty cart
        let cart = window.cart;
        cart.emptyCart();

        // set 'complete' state
        this.setState({ stage: 'complete', products });
      }

      // if not 'complete', create checkout session
      else {

        // if no items in cart yet, redirect back to /courses
        if (cartData.items && cartData.items.length === 0) {
          window.location.replace('/courses');
          return;
        }

        // create checkout session
        let productIds = cartData.items.map(i => i.id);
        let response = await purchaseReturn({ productIds });

        // move to 'checkout' mode
        this.setState({
          stage: 'checkout',
          stripeCheckoutSessionId: response.stripeCheckoutSessionId,
          stripeCheckoutClientSecret: response.stripeCheckoutClientSecret,
        });

      }



    }
    catch(e) {
      console.error('[PurchaseReturn] Error in componentDidMount().', e);
    }
  }
  render() {

    if (this.state.stage === 'loading') {
      return (
        <div>
          <LoadingCenter />
        </div>
      );
    }

    if (this.state.stage === 'checkout') {
      return (
        <div>
          <EmbeddedCheckoutProvider
            stripe={stripePromise}
            options={{ clientSecret: this.state.stripeCheckoutClientSecret }}>
            <EmbeddedCheckout />
          </EmbeddedCheckoutProvider>
        </div>
      );
    }

    if (this.state.stage === 'complete') {

      let pids = getQueryAttribute(window.location.search, 'pids').split(',').map(id => parseInt(id));
      let purchasedProducts = this.state.products.filter(p => pids.indexOf(p.id) > -1);

      // console.log('purchased products', purchasedProducts);

      return (
        <ReturnPurchaseSuccessMessage purchasedProducts={purchasedProducts} />
      );
    }

    return (
      <div>
        Unknown state.
      </div>
    );
  }
}

export default PurchaseReturn;


function ReturnPurchaseSuccessMessage(props) {
  let purchasedProducts = props.purchasedProducts;
  return (
    <div className='repurchaseSuccessMessage'>
      <div className='repurchaseSuccessMessageInner'>
        <h1>Purchase Complete!</h1>
        <br />
        <Image src='/static/images/purchase-success-cat-walking.png' alt='Cat walking' />
        <br />
        <br />
        <p>
          We're processing your payment! {toHumanArray(purchasedProducts.map(p => p.name))} will be added to your course library in a few minutes.
        </p>
        <br />
        <a href='/courses'>
          <button className='pi3button'>Return to Course Library</button>
        </a>
      </div>
    </div>
  );
}