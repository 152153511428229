'use strict';

import React from 'react';
import { Chart } from 'react-google-charts';

// https://developers.google.com/chart/interactive/docs/gallery/piechart#donut
// const activityRatioData = [
//   ['Course', 'Active users'],
//   ['Product Management', 4],
//   ['AthenaHealth Product Management Course', 1],
// ];
class PieChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <Chart chartType='PieChart' data={this.props.data} options={this.props.options} width={this.props.width} height={this.props.height} />
    );
  }
}

// https://developers.google.com/chart/interactive/docs/gallery/barchart
// https://react-google-charts.com/bar-chart#material-design
// const courseCompletionData = [
//   ['Lecture', 'Completed lecture'],
//   ["A Product Manager's Purpose", 18],
//   ['A Roadmap To This Course and Your Role', 16],
// ];
class BarChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return <Chart chartType='BarChart' data={this.props.data} options={this.props.options} width={this.props.width} height={this.props.height} />;
  }
}

// https://developers.google.com/chart/interactive/docs/gallery/linechart
// https://react-google-charts.com/line-chart
// const dailyActivityData = [
//   [
//     { type: 'date', label: 'Date' },
//     { type: 'number', label: 'Product Management' },
//     { type: 'number', label: 'AthenaHealth Product Management Course' },
//     { type: 'number', label: 'Liberty Mutual Product Ownership' },
//     { type: 'number', label: 'JnJ Product Management' }
//   ],
//   [new Date('2019-08-26T00:00:00.000Z'), 100, 40, 39, 21],
//   [new Date('2019-08-27T00:00:00.000Z'), 117, 46, 42, 12],
//   [new Date('2019-08-28T00:00:00.000Z'), 66, 112, 51, 9],
// ];
class LineChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    for (let i = 1; i < this.props.data.length; i++) {
      this.props.data[i][0] = new Date(this.props.data[i][0]);
    }
    return <Chart chartType='LineChart' data={this.props.data} options={this.props.options} width={this.props.width} height={this.props.height} />;
  }
}

export {
  PieChart,
  BarChart,
  LineChart,
};