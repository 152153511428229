
'use strict';

import React from 'react';
import ReactDOM from 'react-dom';



// we'll use native PDF embed now, instead of PDF.js
// this will not work on android chrome (?) so we'll need an alternative option there
// TODO need to fix how file upload works for this component/block
// https://stackoverflow.com/questions/291813/recommended-way-to-embed-pdf-in-html
// https://github.com/mozilla/pdf.js#online-demo

class CourseBlockDocument extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    let block = this.props.block;

    // (0) OG
    // return (
    //   <div className="pi3courseDocument">
    //     <embed src={block.url} type="application/pdf" />
    //   </div>
    // );

    // (1) safari minimal attempt - DOES NOT WORK
    // return (
    //   <div className="pi3courseDocument">
    //     <embed src={block.url} />
    //   </div>
    // );

    // (2.1) safari minimal attempt with fallback
    return (
      <div className='pi3courseDocument'>
        <object data={block.url}>
          {/* <object> */}
          <div className='pi3courseDocumentFallback'>
            <h2>Document embed not supported</h2>
            <p>
              Sorry, your browser doesn't seem to support embedding PDF documents. You can <a href={block.url} target='_new'>open the document</a> in a new tab.
            </p>
          </div>
        </object>
      </div>
    );

    //  <div className="pi3courseDocument">
    //  {/* <embed src={block.url} type="application/pdf" /> */}
    //  {/* <object data={block.url} type="application/pdf" width="100%" height="50%" id="pdfDoc" name="pdfDoc"></object> */}
    //  {/* <object data={block.url} type="application/pdf" id="pdfDoc" name="pdfDoc"></object> */}
    //  {/* <iframe src={block.url} className='pi3courseDocumentIframe'></iframe> */}
    //  </div>
    // BuildingSkillsinPython.pdf#toolbar=1&navpanes=0&scrollbar=1&page=1&view=FitW

    // return (
    //   <div className='pi3courseDocumentPdf'>
    //     <div className='pdf'>
    //       <object data={block.url} type='application/pdf'>
    //         <p>You do not seem to have a functional PDF plug-in for this browser.</p>
    //       </object>
    //     </div>
    //   </div>
    // );

  }
}

export default CourseBlockDocument;