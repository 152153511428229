
import enums from './enums';

const USER_STORAGE = 'pi3user';
var localStorage = null;
if (typeof window !== 'undefined') { // fu ssr
  localStorage = window.localStorage;
}

const eventName = 'user-change';
const subscribers = {
  [eventName]: [],
};

// fetch user from local storage
function getUser() {
  try {
    let user = localStorage.getItem(USER_STORAGE);
    if (user === null) {
      return null;
    }
    try {
      user = JSON.parse(user);
      return user;
    } catch(e) {
      console.error('[Session] Could not parse user from local storage.', e);
      // TODO setUser(null)?
      return null;
    }
  }
  catch(e) {
    console.error('[Session] Error running getUser().', e);
  }
}

function isAdmin(user) {
  try {
    if (user && user.role === enums.User.Role.Admin) {
      return true;
    }
    return false;
  }
  catch(e) {
    console.error('[Session] Error running isAdmin().', e);
  }
}

function isAdminOrCompanyManager(user) {
  try {
    if (user && user.role === enums.User.Role.Admin) {
      return true;
    }

    if (user && user.companyPlans && user.companyPlans.length > 0) {
      return true;
    }
    return false;
  }
  catch(e) {
    console.error('[Session] Error running isAdminOrCompanyManager().', e);
  }
}

// subscribe to user changes
function subscribe(callback) {
  console.log('[Session] Subscribing to user change...');
  subscribers[eventName].push(callback);
  return {
    unsubscribe() {
      subscribers[eventName] = subscribers[eventName].filter((cb) => {
        if (cb === callback) {
          return false;
        }
        return true;
      });
    },
  };
}

// save user change & publish to subscribers
function setUser(newUser) {
  console.log('[Session] Setting new user...', newUser);
  localStorage.setItem(USER_STORAGE, JSON.stringify(newUser));
  subscribers[eventName].forEach((callback) => {
    callback(newUser);
  });
}

function unsetUser() {
  console.log('[Session] Unsetting user...');
  localStorage.removeItem(USER_STORAGE);
  subscribers[eventName].forEach((callback) => {
    callback(null);
  });
}

export {
  getUser,
  isAdmin,
  isAdminOrCompanyManager,

  setUser,
  unsetUser,
  subscribe,
};
