
import { DateTime } from 'luxon';

const STORAGE_KEY = 'cartData';

const WEEK = 604800000; // 1000 * 3600 * 24 * 7
// const WEEK = 60000; // 1000 * 60 // debug

class Cart {
  constructor() {
    // set default cart data
    this.data = this.getEmptyCart();
    // cart state change subscriptions
    this.subscribers = [];
    // attempt loading cart from local storage
    this.tryLoadCart();
  }
  // subscribe to state change
  subscribe(callback) {
    // console.log('[Cart] Subscribing to cart change...');
    this.subscribers.push(callback);
  }
  unsubscribe(callback) {
    // console.log('[Cart] Unsubscribing from cart change...');
    this.subscribers = this.subscribers.filter(cb => cb === callback ? false : true);
  }
  publish(data) {
    // console.log('[Cart] Publishing cart changes...', { data });
    // publish current items & total
    this.subscribers.forEach(callback => callback(data));
  }
  getEmptyCart() {
    let now = DateTime.utc();
    return {
      items: [],
      total: 0,
      created: now.toISO(),
      updated: now.toISO(),
    };
  }
  tryLoadCart() {
    // try loading cart from local storage
    let now = DateTime.utc();
    let cartData = localStorage.getItem(STORAGE_KEY);

    if (cartData) {
      console.log('[Cart] Parsing cart data from local storage...');
      cartData = JSON.parse(cartData);

      let updated = DateTime.fromISO(cartData.updated);
      let age = now.toMillis() - updated.toMillis();
      let isOldCart = (now.toMillis() - updated.toMillis()) > WEEK ? true : false;

      // check if cart is stale - 24hrs old or more?
      console.log(`[Cart] This is the cart status.`, { age, WEEK, isOldCart });
      if (!isOldCart) {
        this.data = cartData;
        this.publish(cartData);
      }
      else {
        // blank out saved cart
        console.log('[Cart] Blanking cart from local storage.');
        this.saveCart(this.data);
      }
    }
    // cart data not in local storage
    else {
      console.log('[Cart] No cart data in local storage.');
      this.saveCart(this.data);
    }
  }

  add(item) {
    let now = DateTime.utc();
    // console.log(`[Cart] Adding item ${item.id} to cart...`);
    let data = this.getCart();
    let existing = data.items.find(i => i.id === item.id);
    if (!existing) {
      data.items.push(item);
      data.total = this.calculateTotal(data.items);
      data.updated = now.toISO();
      // save cart
      this.saveCart(data);
      // notify subscribers that items changed
      this.publish(data);
    }
  }
  replace(item) { // likely deprecated for now
    let now = DateTime.utc();
    let data = this.getCart();
    let found = false;
    for (let i = 0; i < data.items.length; i++) {
      if (data.items[i].id === item.id) {
        // console.log(`[Cart] Replacing item ${item.id} to cart...`);
        data.items[i] = item;
        data.total = this.calculateTotal(data.items);
        data.updated = now.toISO();
        // save cart
        this.saveCart(data);
        // notify subscribers that items & total changed
        this.publish(data);
        found = true;
        break;
      }
    }
    if (!found) {
      console.log(`[Cart] Item ${item.id} not found in cart.`);
    }
  }
  remove(item) {
    let now = DateTime.utc();
    let data = this.getCart();
    // console.log(`[Cart] Removing item ${item.id} from cart...`);
    let existing = data.items.find(i => i.id === item.id);
    if (existing) {
      let index = data.items.indexOf(existing);
      data.items.splice(index, 1);
      data.total = this.calculateTotal(data.items);
      data.updated = now.toISO();
      // save cart
      this.saveCart(data);
      // notify subscribers that items changed
      this.publish(data);
    }
  }
  emptyCart() {
    let empty = this.getEmptyCart();
    // save cart
    this.saveCart(empty);
    // notify subscribers
    this.publish(empty);
  }
  isItemInCart(item) {
    let data = this.getCart();
    // console.log(`[Cart] Checking if item ${item.id} is in cart...`);
    let existing = data.items.find(i => i.id === item.id);
    return existing ? true : false;
  }
  getCart() {
    return {
      items: this.data.items,
      total: this.data.total,
      created: this.data.created,
      updated: this.data.updated,
    };
  }
  saveCart(data) {
    this.data = data;
    // store cart to local storage
    localStorage.setItem(STORAGE_KEY, JSON.stringify(data));
  }
  calculateTotal(items) {
    // calculate total price based on what's in the cart
    let total = 0;
    for (let item of items) {
      if (item.stripeProduct && item.stripeProduct.price) {
        total += item.stripeProduct.price.amount;
      }
    }
    return total;
  }
}

export default Cart;


