
import React from 'react';

export const GTableRow = function (props) {
  return (<>
    <tr style={{borderColor: 'transparent', borderBottom: '1px solid #ddd', ...props?.style}}>
      {props.children}
    </tr>
  </>);
};
