
'use strict';

import React from 'react';

import { DateTime } from 'luxon';

import { getCourses, getCertificates } from './ajax';

import LoadingCenter from './LoadingCenter';


class CourseCertificates extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      state: 'loading', // 'loading', 'unavailable', 'generate', 'generating', 'download'
    };
  }


  async componentDidMount() {
    try {
      console.log('[CourseCertificates] Running componentDidMount()...');

      // fetch courses
      let courses = await getCourses();
      console.log('[CourseCertificates] Fetched courses?', { courses });
      // fetch certificates
      let certificates = await getCertificates();
      console.log('[CourseCertificates] Fetched certificates?', { courses });



      this.setState({
        state: 'loaded',
        courses: courses,
        certificates: certificates,
      });
    }
    catch(e) {
      console.error('[CourseCertificates] Error in componentDidMount().', e);
    }

  }
  render() {

    if (this.state.state === 'loading') {
      return (
        <div className='pi3courseCertificateList'>
          <h1>Course Certificates</h1>
          <LoadingCenter />
        </div>
      );
    }


    let certificates = [];
    let almostCertificates = []; // courses done 100% so elligible for certificates
    let availableCourses = [];
    let unavailableCourses = [];


    if (this.state.state === 'loaded') {

      for (let course of this.state.courses) {

        let certificate = this.state.certificates.find(c => c.courseId === course.id);

        // check if has certificate
        if (certificate) {
          // console.log('Course has certificate!', { course, certificate });
          certificates.push(
            <CertificatePreviewSmall key={course.id} course={course} certificate={certificate} />
          );
        }

        else if (course.progress && course.progress.completedPercent === 100) {
          almostCertificates.push(
            <AlmostCertificate key={course.id} course={course} />
          );
        }

        else if (course.isAvailable) {
          availableCourses.push(
            <NoCertificateYet key={course.id} course={course} />
          );
        }

        else {
          unavailableCourses.push(
            <DisabledCourse key={course.id} course={course} />
          );
        }
      }
    }

    return (
      <div className='pi3courseCertificateList'>
        <h1>Course Certificates</h1>
        <br />
        <div className='pi3courseCertificates'>
          {certificates}
          {almostCertificates}
          {availableCourses}
          {unavailableCourses}

          {/* {items.length === 0 &&
          <div>
            You do not have any course certificates yet.
          </div> */}
          
        </div>
      </div>
    );
  }
}

export default CourseCertificates;

function CertificatePreviewSmall(props) {
  let course = props.course;
  let certificate = props.certificate;

  return (
    <div className='pi3certificatesItem'>
      <a href={`/course/${course.id}/certificate`}>
        <div className="pi3certificatePreviewSmall">
          <div className="background">
            <img src="https://pi2-web.s3.amazonaws.com/img/certificate-background.png" />
          </div>
          <div className="foreground">
            <h1>Certificate of Completion</h1>
            <h4>Awarded to</h4>
            <h2>{certificate.name}</h2>
            <h4>For completing the course</h4>
            <h3>{certificate.courseName}</h3>
          </div>
        </div>
        <strong>{course.name}</strong>
      </a>
    </div>
  );

}

function AlmostCertificate(props) {
  let course = props.course;

  return (
    <div className='pi3certificatesItem'>
      <a href={`/course/${course.id}/certificate`}>
        <div className='pi3certificatePreviewSmallNotDone'>
          <div>{course.progress.completedPercent}%</div>
          <div className='pi3certificatePercent'>
            <div className='pi3certificatePercentInner' style={{ width: `${course.progress.completedPercent}%` }}></div>
          </div>

        </div>
        <strong>{course.name}</strong>
      </a>
    </div>
  );
}



function NoCertificateYet(props) {
  let course = props.course;

  return (
    <div className='pi3certificatesItem'>
      <a href={`/course/${course.id}`}>
        <div className='pi3certificatePreviewSmallNotDone'>
          <div>{course.progress.completedPercent}%</div>
          <div className='pi3certificatePercent'>
            <div className='pi3certificatePercentInner' style={{ width: `${course.progress.completedPercent}%` }}></div>
          </div>

        </div>
        <strong>{course.name}</strong>
      </a>
    </div>
  );
}

function DisabledCourse(props) {
  let course = props.course;

  return (
    <div className='pi3certificatesItem'>

      <div className='pi3certificatePreviewSmallNotDone'>
        <div>{course.progress.completedPercent}%</div>
        <div className='pi3certificatePercent'>
          <div className='pi3certificatePercentInner' style={{ width: `${course.progress.completedPercent}%` }}></div>
        </div>

      </div>
      <strong>{course.name}</strong>

    </div>
  );
}