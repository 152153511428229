
import React from 'react';
import {valueListToArray} from '../../../../src/utilities/Array.js';

export const GIcon = function (props) {

  return (<>
    {
      // Vertical icon alignment needs to be done with text font and line height in mind.
      // @see https://blog.prototypr.io/align-svg-icons-to-text-and-say-goodbye-to-font-icons-d44b3d7b26b4
    }
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width={props.width}
      height={props.height}
      viewBox={`0 0 ${props.width} ${props.height}`}
      role="img"
      style={{
        display: 'inline-block',
        width: '1em',
        height: '1em',
        boxSizing: 'border-box',
        position: 'relative',
        top: '-0.0625em',
        fill: 'currentColor',
        ...(valueListToArray(props?.variant).includes('inline') ? {
          // `  vertical-align: baseline;` aligns the icon as if it were a letter, placing it directly on the baseline.
          // This behavior is expected when icons are included as part of a text and not treated as separate
          // `display: block;` or `display: block;` elements.
          verticalAlign: 'baseline',
          position: 'relative',
          top: '0.0625em',
        } : {}),
        ...props?.style,
      }}
    >
      {props?.children}
    </svg>
  </>);

};
