import React from 'react';


class Checkout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {

    return (
      <div>
        <div className='pi2container'>
          <div className='pi2content'>
            <div className='newPurchasePricing'>

              <div className='purchaseFlowSelect'>
                <div className='purchaseFlowSelectIndividuals purchaseFlowSelectItem'>
                  <h2>For Individuals</h2>
                  <img src='/static/images/purchase-flow-individuals@2x.png' alt='For Individuals' />
                  <p>I’m an <strong>individual</strong> learner, looking for self-paced courses</p>
                  
                  <a href="/purchase/new">
                    <button className='pi4button pi2uppercase'>Choose your Courses</button>
                  </a>
                </div>

                <div className='purchaseFlowSelectTeams purchaseFlowSelectItem'>
                  <h2>For Teams</h2>
                  <img src='/static/images/purchase-flow-teams@2x.png' alt='For Teams' />
                  <p>I’m a manager looking for training for my <strong>whole team</strong>.</p>

                  <a href="/purchase/teams">
                  <button className='pi4button pi2uppercase'>Choose your Team Plan</button>
                  </a>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    );

  }
}




export default Checkout;



