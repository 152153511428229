
import React from 'react';

import { DateTime } from 'luxon';

import { getManagerReport } from './ajax';
import enums from './enums';

import LoadingCenter from './LoadingCenter';


import { PieChart } from './GoogleCharts';
import LectureCompletionGraph from './DashboardLectureCompletionGraph';
import QuizCompletionGraph from './DashboardQuizCompletionGraph';


// TODO new spec: <CompanyContainer companyId={id} />

class CompanyContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isReportLoading: true,
      report: null,
      selectedPlanId: null,
    };
    this.getReport = this.getReport.bind(this);
    this.onPlanToggle = this.onPlanToggle.bind(this);
    this.onPlanSelect = this.onPlanSelect.bind(this);
  }
  async componentDidMount() {
    try {
      // company/plans are here, get report for them
      let companyPlanId = this.props.company.plans.find(p => p.isSelected).id;
      await this.getReport(companyPlanId);
    }
    catch(e) {
      console.error('[CompanyContainer] Error in componentDidMount().'. e);
    }
  }
  async componentDidUpdate(prevProps) {
    try {
      if (this.props.company.id !== prevProps.company.id) {
        // companyId changed, envoke loading another company
        console.log(`[CompanyContainer] Company identifier changed.`, {
          company: this.props.company, old: prevProps.company });
        this.setState({ company: this.props.company });

        await this.getReport(this.props.company.plans.find(p => p.isSelected).id);
      }
    
    } catch(e) {
      console.error('[CompanyContainer] Error in componentDidUpdate().'. e);
    }
  }
  onPlanToggle(planId, isSelected) { // deprecated multi-plan select
    console.log(`[CompanyContainer] Toggling plan ${planId} visibility to ${isSelected}...`);
    if (this.state.company && this.state.company.plans) {
      let company = this.state.company;
      for (let plan of company.plans) {
        if (plan.id === planId) {
          plan.isSelected = isSelected;
        }
      }
      this.setState({ company });
      // TODO fetch report again
      this.getReport(planId);
    }

  }
  onPlanSelect(planId) {
    console.log(`[CompanyContainer] Selecting planId ${planId}...`);
    let company = this.props.company;
    if (company && company.plans) {
      for (let plan of company.plans) {
        if (plan.id === planId) {
          plan.isSelected = true;
        }
        else {
          plan.isSelected = false;
        }
      }
      // fetch report again
      this.getReport(planId);
    }

  }

  async getReport(planId) {
    try {
      console.log('[CompanyContainer] Fetching report...', { planId });
      this.setState({ isReportLoading: true });
      let report = await getManagerReport(planId); 
      this.setState({ report, isReportLoading: false });
    }
    catch(e) {
      console.error('[CompanyContainer] Error in getReport()', e);
    }
  }
  render() {
    if (this.state.isLoading && !this.props.company) {
      return <LoadingCenter />;
    }

    let company = this.props.company;

    let reportPanel = '';
    let downloadPanel = '';

    if (this.state.isReportLoading) {
      reportPanel = <LoadingCenter />;
      downloadPanel = '';
    }
    else if (this.state.report === null) {
      reportPanel = (
        <div className='managerReportNoPlansScreen'>
          <div className='managerReportNoPlansScreenTitle'>No plans selected</div>
        </div>
      );
      downloadPanel = '';
    }
    else if (this.state.report && this.state.report.json && this.state.report.json.courseReports.length === 0) {
      // report fetched but empty
      reportPanel = (

        <div className='managerReportNoDataScreen'>
          <div className='managerReportNoDataTitle'>Your Product Institute plan has just begun!</div>
          Data will be displayed here as soon as enough of your team begun using Product Institute to generate a report.
          Reports will breakdown your team's daily activity, and how your team is advancing in each course.
          Have your team start enrolling in classes, and check back here for Reporting.
        </div>
      );
      downloadPanel = '';
    }
    else {

      let selectedPlan = this.props.company.plans.find(p => p.isSelected === true);
      let report = this.state.report;

      reportPanel = (
        <div className='reportNavigator'>
          <ReportNavigator 
            courseReports={report.json.courseReports}
            endDate={report.json.endDate}
            plan={selectedPlan}
          />
        </div>
      );

      downloadPanel = <DownloadExcelButton2 link={this.state.report.excelDownloadLink} />;
    }


    return (
      <div className='managerReportCompanyContainer'>

        {/* list of plans */}
        <PlanPanelList plans={company.plans} onPlanSelect={this.onPlanSelect} />

        <div className='linebreak'></div>

        {/* plan selector & report viewer */}

        <div className='managerReportContainer'>
          <div className='managerReportNavigator'>
            <div>{downloadPanel}</div>
          </div>

          {reportPanel}

        </div>

      </div>
    );
  }
}

const SelectedTab = {
  Current: 1,
  Expired: 2,
};

class PlanPanelList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: SelectedTab.Current
    };
    this.changeTab = this.changeTab.bind(this);
  }
  changeTab(tab) {
    console.log('[PlanPanelList] Changing tab...', tab);
    this.setState({ tab });
  }
  render() {
    // TODO calculate current plan count
    let currentPlans = this.props.plans.filter(p => !p.isPlanExpired);
    let expiredPlans = this.props.plans.filter(p => p.isPlanExpired);

    let components = [];
    let isCurrentTabSelected = this.state.tab === SelectedTab.Current;
    if (isCurrentTabSelected) {
      // console.log('current plans are selected');
      components = currentPlans.map(p => <PlanPanel key={p.id} plan={p} onPlanSelect={this.props.onPlanSelect} />);
    }
    else {
      // console.log('expired plans are selected');
      components = expiredPlans.map(p => <PlanPanel key={p.id} plan={p} onPlanSelect={this.props.onPlanSelect} />);
    }

    return (
      <div>
        <div className='planPanelListTabs'>
          <span
            className={isCurrentTabSelected ? `planPanelListTab planPanelListTabActive` : `planPanelListTab`} onClick={() => this.changeTab(SelectedTab.Current)}>
            Current Plans
          </span>
          <span
            className={isCurrentTabSelected ? `planPanelListTab` : `planPanelListTab planPanelListTabActive`} onClick={() => this.changeTab(SelectedTab.Expired)}>
            Expired Plans
          </span>
        </div>
        <div className='planPanelList'>
          {components}
          {components.length === 0 &&
            <p>No plans in this category.</p>
          }
        </div>
      </div>
    );

  }
}

class PlanPanel extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    let plan = this.props.plan;

    let now = DateTime.utc();
    let startsAt = DateTime.fromISO(plan.startsAt);
    let expiresAt = DateTime.fromISO(plan.expiresAt);

    // console.log('koji urac', { now, startsAt, expiresAt });

    let planStatus = '';
    if (now > expiresAt) {
      planStatus = <span>Expired</span>;
    }
    else if (now > startsAt && now < expiresAt) {
      planStatus = <span>Active</span>;
    }
    else if (now > startsAt) {
      planStatus = <span>Future</span>;
    }

    let typeLabel = '';
    if (plan.type === enums.CompanyPlan.Type.TeamsStandard ||
        plan.type === enums.CompanyPlan.Type.LegacyStandard) {
      typeLabel = 'Standard plan';
    }
    else if (plan.type === enums.CompanyPlan.Type.TeamsPremium ||
        plan.type === enums.CompanyPlan.Type.LegacyPremium) {
      typeLabel = 'Premium plan';
    }
    else if (plan.type === enums.CompanyPlan.Type.TeamsEnterprise ||
        plan.type === enums.CompanyPlan.Type.LegacyEnterprise) {
      typeLabel = 'Enterprise plan';
    }

    let enrolledLearners = plan.actualUserCount;
    let availableSeats = plan.totalSeats - plan.actualUserCount;
    if (availableSeats < 0) availableSeats = 0;

    let planSeatPanel = '';
    let openLicensesLabel = '';
    if (plan.type === enums.CompanyPlan.Type.TeamsEnterprise ||
        plan.type === enums.CompanyPlan.Type.LegacyEnterprise) {
      // plans have unlimited seats
      planSeatPanel = <h2>{enrolledLearners} Enrolled Learners</h2>;
    }
    else if (plan.type === enums.CompanyPlan.Type.TeamsStandard ||
        plan.type === enums.CompanyPlan.Type.TeamsPremium ||
        plan.type === enums.CompanyPlan.Type.LegacyStandard ||
        plan.type === enums.CompanyPlan.Type.LegacyPremium) {
      planSeatPanel = <h2>{enrolledLearners}/{plan.totalSeats} Enrolled Learners</h2>;
      openLicensesLabel = <p>{availableSeats} Open Licenses</p>;
    }

    let classy = plan.isSelected ? 'planPanel active' : 'planPanel';

    return (
      <div className={classy} onClick={() => this.props.onPlanSelect(plan.id)}>
        <h1>{plan.name}</h1>
        {planSeatPanel}
        <p>{typeLabel}</p> 
        <p>{planStatus}</p>
        <p>{startsAt.toFormat('LLLL d, yyyy')} - {expiresAt.toFormat('LLLL d, yyyy')}</p>
        {openLicensesLabel}
        <span className='planPanelOverlay'>
        </span>
      </div>
    );
  }
}



{/* <ReportNavigator courseReports={this.state.courseReports} plan={plan} endDate={this.state.endDate} /> */}
class ReportNavigator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  render() {
    // https://react-google-charts.com/bar-chart#material-design
    let courseCharts = [];
    for (let chubby of this.props.courseReports) {

      let availableSeats = this.props.plan.totalSeats - this.props.plan.actualUserCount;
      if (availableSeats < 0) availableSeats = 0;
      let enrolledLearnersLabel = '';
      let availableSeatsLabel = '';

      if (this.props.plan.type === enums.CompanyPlan.Type.TeamsEnterprise ||
        this.props.plan.type === enums.CompanyPlan.Type.LegacyEnterprise) {
        enrolledLearnersLabel = <p>
          {this.props.plan.actualUserCount} Enrolled Learners
        </p>;
      }
      else {
        enrolledLearnersLabel = <p>{this.props.plan.actualUserCount}/{this.props.plan.totalSeats} Enrolled Learners</p>;
      }

      let planSeatPanel = '';
      if (this.props.plan.type === enums.CompanyPlan.Type.TeamsStandard ||
          this.props.plan.type === enums.CompanyPlan.Type.LegacyStandard ||
          this.props.plan.type === enums.CompanyPlan.Type.TeamsPremium ||
          this.props.plan.type === enums.CompanyPlan.Type.LegacyPremium) {
        planSeatPanel = (
          <CompanyPlanPieChart
            enrolledLearners={this.props.plan.actualUserCount}
            learnersCompleted={chubby.totalCourseCompleted}
            learnersInProgress={chubby.totalUsersInProgress}
            openLicenses={availableSeats}
          />
        );
        availableSeatsLabel = <p>{availableSeats} Open Licenses</p>;
      }

      else if (this.props.plan.type === enums.CompanyPlan.Type.TeamsEnterprise ||
              this.props.plan.type === enums.CompanyPlan.Type.LegacyEnterprise) {
        planSeatPanel = (
          <CompanyPlanPieChart
            enrolledLearners={this.props.plan.actualUserCount}
            learnersCompleted={chubby.totalCourseCompleted}
            learnersInProgress={chubby.totalUsersInProgress}
          />
        );
      }

      courseCharts.push(
        <div key={chubby.id } className='courseCompletionChart'>
          <div className='courseCompletionChartHeader'> 

            <div className='courseCompletionChartLeft'>
              <div className='lougou'>
                <img src='/static/images/reporting/course_icon2.svg' />
              </div>
              <div className='noulougou'>
                <h2>{chubby.name}</h2>
                {enrolledLearnersLabel}
                {availableSeatsLabel}
                <p>{chubby.totalCourseCompleted} Completed the Course as of {DateTime.fromISO(this.props.endDate).toUTC().toFormat('LLL d yyyy')}</p>
              </div>
            </div>

            <div className='courseCompletionChartRight'>
              <div className='planSeatPanelBox'>
                {planSeatPanel}
              </div>
            </div>

          </div>

          <br />

          <div className='boxie'>
            <div className='kontejner'>
              <div className="ajtem ajtemLeft">
                <h2>Unit Progress</h2>
                <div className='legenda'>
                  <LegendItem text='Completed' color={'#76003b'} />
                  &nbsp;&nbsp;&nbsp;
                  <LegendItem text='In progress' color={'#e5aaab'} />
                  &nbsp;&nbsp;&nbsp;
                  <LegendItem text='Not started' color={'#d9d9d9'} />
                </div>
                <LectureCompletionGraph
                  data={chubby.sectionCompletionGraph}
                  actualUserCount={this.props.plan.actualUserCount}
                  plan={this.props.plan} />
              </div>

              <div className="ajtem ajtemRight">
                <h2>Quizzes</h2>
                <div className='legenda'>
                  <LegendItem text='Pass' color={'#14b99b'} />
                  &nbsp;&nbsp;&nbsp;
                  <LegendItem text='Fail' color={'#fe1f1f'} />
                </div>
                <QuizCompletionGraph data={chubby.quizCompletionGraph} />
              </div>
            </div>
          </div>

          <br />
          <br />

        </div>
      );
    }
    return (
      <div>
        <div className='boxie'>
          {courseCharts}
        </div>
      </div>
    );
  }
}

// https://developers.google.com/chart/interactive/docs/gallery/piechart
class CompanyPlanPieChart extends React.Component {
  constructor(props) {
    super(props);
    this.width = 120;
    this.height = 120;
    this.options = {
      // pieHole: .75,
      pieSliceText: 'none',
      // pieSliceBorderColor: 'transparent',
      pieSliceBorderColor: '#76003b',
      colors: [ '#76003b', '#e5a9ab', '#fff' ],
      enableInteractivity: false,
      backgroundColor: { fill: 'edeff6', strokeWidth: 0 },
      legend: { position: 'none' },
      chartArea: {
        top: 1,
        left: 1,
        width: 118,
        height: 118,
      }
    };
  }
  render() { 
    let graphData = [
      ['', ''],
      ['Completed', this.props.learnersCompleted],
      ['In Progress', this.props.learnersInProgress],
    ];
    if (this.props.openLicenses >= 0) {
      graphData.push(['Open Licenses', this.props.openLicenses]);
    }
    return (
      <div className='companyPlanPieChart'>
        <div className='companyPlanPieChartChart'>
          <PieChart data={graphData} options={this.options} width={this.width} height={this.height} />
        </div>
        <div className='companyPlanPieChartLegend'>
          <div style={{ width: '16px', height: '16px', display: 'inline-block', borderRadius: '8px', background: '#76003b', position: 'relative', top: '0px', margin: '0 10px 0 0' }}></div>
          <span className=''>{this.props.learnersCompleted}</span> <span className=''>Completed</span>
          <br />
          <div style={{ width: '16px', height: '16px', display: 'inline-block', borderRadius: '8px', background: '#e5a9ab', position: 'relative', top: '0px', margin: '0 10px 0 0' }}></div>
          <span className=''>{this.props.learnersInProgress}</span> <span className=''>In Progress</span>
          {this.props.openLicenses >= 0 &&
            <span>
              <br />
              <div style={{ width: '15px', height: '15px', display: 'inline-block', borderRadius: '8px', border: '1px solid #76003b', background: '#fff', position: 'relative', top: '0px', margin: '0 10px 0 0' }}></div>
              <span className=''>{this.props.openLicenses}</span> <span className=''>Open Licenses</span>
            </span>
          }
          <br />
        </div>
      </div>
    );
  }
}

class LegendItem extends React.Component {
  render() {
    return (
      <div className='legendItem'>
        <div style={{ width: '16px', height: '16px', margin: '0', display: 'inline-block', borderRadius: '8px', background: this.props.color, top: '-2px' }}></div>
        <span style={{ textTransform: 'uppercase', fontSize: '18px', lineHeight: '10px', fontWeight: 'bold', top: '-3px', padding: '0 0 0 5px' }}>{this.props.text}</span>
      </div>
    );
  }
}

//<DownloadExcelButton companyId={this.state.companyId} companyName={this.state.data.company.name} endDate={this.state.endDate} />
class DownloadExcelButton2 extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <a href={this.props.link} download={this.props.filename}>
        <button type="button" className="managerReportNavigatorButton">
          Download spreadsheet
        </button>
      </a>
    );
  }
}




export default CompanyContainer;