
import React from 'react';

export const GTableHeader = function (props) {
  return (<>
    <thead>
      <tr style={{boxSizing: 'border-box', borderBottom: '1px solid #ddd'}}>
        {props.children}
      </tr>
    </thead>
  </>);
};
