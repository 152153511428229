
import React from 'react';

export const GListItem = function (props) {

  return (<>
    <li
      style={{
        ...props?.style,
      }}
    >
      {props?.children}
    </li>
  </>);

};
