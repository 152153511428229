import React from 'react';

import { getLicenses, signLicense } from './ajax';
import { getQueryAttribute } from './helpers';

import LoadingCenter from './LoadingCenter';
import { FormCheckbox } from './FormElements';


class License extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      state: 'loading', // 'loaded', 'signing'
      licenses: [],
      signed: false,
    };
    this.sign = this.sign.bind(this);
  }

  async componentDidMount() {
    try {
      // TODO load licenses to sign
      let licenses = await getLicenses();

      this.setState({
        state: 'loaded',
        licenses: licenses,
      });
    }
    catch(e) {
      console.error('[License] Error in componentDidMount()', e);
    }
  }

  async sign(licenseId) {
    try {
      this.setState({ state: 'signing' });

      let signed = await signLicense(licenseId);

      // check if we have redirect URL encoded in URL
      let encodedUrl = getQueryAttribute(window.location.search, 'r');
      if (encodedUrl) {
        let decodedUrl = decodeURIComponent(encodedUrl);
        location.replace(decodedUrl);
        return;
      }
      // redirect to /courses
      location.replace('/courses');
    }
    catch(e) {
      console.error('[License] Error signing license', e);
    }
  }

  render() {

    if (this.state.state === 'loading') {
      return (
        <div>
          <h1>We’ve updated our Terms</h1>
          <br />
          <LoadingCenter />
        </div>
      )
    }

    if (this.state.state === 'loaded' || 'signing') {
      let licenses = [];

      // no licenses to sign
      if (this.state.licenses.length === 0) {
        return (
          <div>
            <h1>We’ve updated our Terms</h1>
            <br />
            <p>There are no new Terms & Conditions to sign.</p>
          </div>
        );
      }

      else {
        let firstLicense = this.state.licenses[0];

        let termsLabel = (<span>I agree to <a href={firstLicense.licenseUrl} target='_blank'>Product Institute's Terms</a></span>);

        return (
          <div>
            <h1>We’ve updated our Terms</h1>
            <div>
              <br />
              <p>
                We've updated our Terms since you have signed up with Product Institute. You can read them <a href={firstLicense.licenseUrl} target='_new'>here</a> before continuing with your course experience.
              </p>
              <br />
              <div className="formRow">
                <FormCheckbox
                  isChecked={this.state.signed}
                  isDisabled={false}
                  label={termsLabel}
                  onChange={() => this.setState({ signed: !this.state.signed })}
                />
              </div>
              <br />
              <div className="submitRowCenter">
                {
                  this.state.state === 'loaded' && this.state.signed &&
                  <button className='pi3button' onClick={() => this.sign(firstLicense.id)}>Continue</button>
                }
                {
                  this.state.state === 'loaded' && !this.state.signed &&
                  <button className='pi3button' disabled>Continue</button>
                }
                {
                  this.state.state === 'signing' &&
                  <button className='pi3button' disabled>Continue</button>
                }
              </div>

            </div>
          </div>
        );
      }

    }

    return '';
 
  }
}

export default License;
