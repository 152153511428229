
'use strict';

import React from 'react';


class CourseBlockCustomCode extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    let block = this.props.block;
    return (
      <div
        className='pi3courseCustomCode'
        dangerouslySetInnerHTML={{__html: block.html}}
      ></div>
    );
  }
}

export default CourseBlockCustomCode;