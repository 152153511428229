
'use strict';

import React from 'react';

import { DateTime } from 'luxon';

import { getCourse, getCourseProgress, generateCertificate } from './ajax';

import LoadingCenter from './LoadingCenter';


class CourseCertificate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      state: 'loading', // 'loading', 'unavailable', 'generate', 'generating', 'download'
    };
    this.loadCertificateStatus = this.loadCertificateStatus.bind(this);
    this.onGenerateCertificate = this.onGenerateCertificate.bind(this);
  }
  async loadCertificateStatus() {
    try {
      let courseId = this.props.courseId;

      // fetch course
      let course = await getCourse(courseId);
      // fetch course completion state
      let progress = await getCourseProgress(courseId);

      console.log('[CourseCertificate] Fetched course and progress?', { course, progress });

      // certificate exists
      if (progress.certificate) {
        this.setState({
          state: 'download',
          course,
          progress
        });
      }
      // course is not completed yet
      else if (progress.completedPercent < 100) {
        this.setState({
          state: 'unavailable',
          course,
          progress
        });
      }
      // course is done, but we haven't generated certificate yet
      else if (progress.completedPercent === 100) {
        this.setState({
          state: 'generate',
          course,
          progress
        });
      }

    }
    catch(e) {
      console.error('[CourseCertificate] Error in loadCertificateStatus().', e);
    }
  }
  async onGenerateCertificate() {
    try {

      let courseId = this.props.courseId;
      console.log('[CourseCertificate] Generating certificate...', { courseId });

      this.setState({ state: 'generating' });

      // INPROG request certificate via ajax
      let certificate = await generateCertificate(courseId);

      console.log('[CourseCertificate] Generated certficate.', certificate);

      let progress = this.state.progress;
      progress.certificate = certificate;
      this.setState({ state: 'download', progress });



    }
    catch(e) {
      console.error('[CourseCertificate] Error in generateCertificate().', e);
    }
  }
  async componentDidMount() {
    try {

      await this.loadCertificateStatus();


    }
    catch(e) {
      console.error('[CourseCertificate] Error in componentDidMount().', e);
    }

  }
  render() {

    if (this.state.state === 'loading') {
      return (
        <LoadingCenter />
      );
    }

    let course = this.state.course;
    let progress = this.state.progress;

    // course not completed yet
    if (this.state.state === 'unavailable') {
      return (
        <div className='pi3courseCertificate'>
          <h1>{course.name}</h1>

          <div className='notDoneCat'>
            <svg width="366" height="311" xmlns="http://www.w3.org/2000/svg">
              <image href='/static/images/certificate-incomplete.svg' />
            </svg>
          </div>

          <h1 className='notDoneYet'>You’re not done just yet!</h1>

          <div className='mustCompleteAll'>
            You must complete all lessons, Videos, and quizzes before receiving your certificate. 
          </div>

          <div className='mustCompleteLesson'>
            <svg width="380" height="59" xmlns="http://www.w3.org/2000/svg">
              <image href='/static/images/certificate-lesson-completed.svg' />
            </svg>
          </div>

          <div className='mustCompleteParagraph'>
            Look for the red checkmark to indicate if a lesson is completed.  Have you completed a lesson that isn't marked? Go back and find the "complete and continue" button at the bottom of the lesson page.
          </div>

        </div>
      );
    }

    // course completed, but certificate not generated yet
    if (this.state.state === 'generate') {
      return (
        <div className='pi3courseCertificate'>
          <h1>{course.name}</h1>

          <div className='doneCat'>
            {/* <img src='/static/images/certificate-complete.png' /> */}
            <img src='/static/images/course-done.png' />
          </div>

          <h1 className='notDoneYet'>You’re done!</h1>

          <div className='generateParagraph'>
            Congratulations on completing the course! Tap below to start making your certificate.
          </div>

          <div className='generateButtons'>
            <button onClick={() => { this.onGenerateCertificate(); }} className='pi3button'>Generate Certificate</button>
          </div>

        </div>
      );
    }


    if (this.state.state === 'generating') {
      return (
        <div className='pi3courseCertificate'>
          <h1>{course.name}</h1>

          <div className='doneCat'>
            {/* <img src='/static/images/certificate-complete.png' /> */}
            <img src='/static/images/course-done.png' />
          </div>

          <h1 className='notDoneYet'>You’re done!</h1>

          <div className='generateParagraph'>
            Generating your certificate, please wait...
          </div>

          <div className='generateButtons'>
            <button className='pi3button' disabled>Generating...</button>
          </div>


        </div>
      );
    }


    if (this.state.state === 'download') {

      let certificate = this.state.progress.certificate;

      return (



        <div className='pi3courseCertificate'>
          <h1>{course.name}</h1>

          <div className='pi3certificateContainer'>
            <CertificatePreview certificate={certificate} />
          </div>

          <h1 className='doneNow'>Congratulations!</h1>

          <div className='haveCompletedAll'>
            You have completed {course.name}
          </div>

          <div className='generateButtons'>
            <a href={certificate.url} target='_new'>
              <button className='pi3button'>Download certificate</button>
            </a>
          </div>

        </div>
      );
    }


    return (
      <div className='pi3courseCertificate'>
        <h1>{course.name}</h1>
        Unknown state; please contact <a href="mailto:info@productinstitute.com">info@productinstitute.com</a> if you're having certificate problems.
      </div>
    );
  }
}

export default CourseCertificate;


// <CertificatePreview certificate={certificate} />

function CertificatePreview(props) {
  let certificate = props.certificate;
  let completedAt = DateTime.fromISO(certificate.completedAt).toFormat('yyyy-LL-dd');


  return (

    <div className="pi3certificatePreview">

      <div className="background">
        <img src="https://pi2-web.s3.amazonaws.com/img/certificate-background.png" />
      </div>

      <div className="foreground">
        <h1>Certificate of Completion</h1>
        <h4>Awarded to</h4>
        <h2>{certificate.name}</h2>
        <h4>For completing the course</h4>
        <h3>{certificate.courseName}</h3>
        <h5>{completedAt}</h5>

        <div className="serial">
          <p>Serial No.</p>
          <h6>{certificate.serial}</h6>
        </div>

      </div>

    </div>
  );


}




