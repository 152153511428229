
import React from 'react';
import {useRouter} from '../../../../src/pi3-frontend/Router.js';

export const GRouterLink = function (props) {

  const router = useRouter();

  const navigate = (event) => {
    //event.preventDefault();
    //event.stopPropagation();
    //router.push(props.to);
  };

  return (<>
    <a
      href={router.resolve(props.to)?.href}
      onClick={navigate}
      style={{...props?.style}}
    >
      {props?.children}
    </a>
  </>);

};
