
'use strict';

import React from 'react';
import ReactDOM from 'react-dom';


class CourseBlockText extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    let block = this.props.block;
    return (
      <div
        className='pi3courseText'
        dangerouslySetInnerHTML={{__html: block.html}}
      ></div>
    );
  }
}

export default CourseBlockText;