
import React from 'react';


// <Modal isOpen={isOpen} isEasyDismiss={true} onClose={} />
class Modal extends React.Component { 
  constructor(props) {
    super(props);
    this.state = {
      // isOpen: this.props.isOpen, // unnecessary!
    };
    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);

  }
  handleClickOutside(event) {
    if (this.props.isOpen && this.props.isEasyDismiss) {
      if (this.wrapperRef && this.wrapperRef.current && !this.wrapperRef.current.contains(event.target)) {
        console.log('[Modal] Handling clickOutside...');
        this.props.onClose();
      }
    }
  }
  async componentDidMount() {
    try {
      document.addEventListener('mousedown', this.handleClickOutside);
    }
    catch(e) {
      console.error('[Modal] Error in componentDidMount().', e);
    }
  }
  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }
  render() {
    if (!this.props.isOpen) {
      return '';
    }
    return (
      <div className='pi3modalContainer'>
        <div className='pi3modal' ref={this.wrapperRef}>

          {/* <div className='pi3modalClose'>
            <img src='/static/images/icon-close-small.svg' />
          </div> */}

          {this.props.children}

        </div>
      </div>
    );
  }
}

export default Modal;
