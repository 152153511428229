
import React from 'react';

export const GTableHeaderCell = function (props) {
  return (<>
    <th style={{verticalAlign: 'middle', textAlign: 'left', overflow: 'hidden', padding: 'var(--spacing-1_5)'}}>
      {props.children}
    </th>
  </>);
};
