
import React from 'react';
import {IconMagnifier} from '../../../../src/pi3-frontend/icons/IconMagnifier.jsx';

export const GInputSearch = function (props) {

  return (<>
    <div
      style={{
        boxSizing: 'border-box',
        display: 'inline-flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        padding: 'var(--spacing-1)',
        borderRadius: 'var(--border-radius-base)',
        borderWidth: '1px',
        borderColor: 'transparent',
        background: 'var(--color-pi3-lightest-grey)',
        fontFamily: 'Nunito Sans',
        ...props?.style,
      }}
    >
      <IconMagnifier
        variant='inline'
        style={{
          flex: 'none',
          fontSize: 'var(--text-sm-fs)',
          lineHeight: 'var(--text-sm-lh)',
          background: 'transparent',
          marginLeft: 'var(--spacing-1)',
          marginRight: 'var(--spacing-1)',
        }}
      />
      <input
        type="text"
        placeholder={props?.placeholder}
        value={props?.value ?? ''}
        onChange={(e) => { props?.onChange?.(e.target.value); }}
        style={{
          flex: '1 1 0%',
          fontSize: 'var(--text-sm-fs)',
          lineHeight: 'var(--text-sm-lh)',
          outline: '2px solid transparent',
          outlineOffset: '2px',
          border: 'none',
          width: '100%',
          background: 'transparent',
        }}
      />
    </div>
  </>);

};
