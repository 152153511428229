import React from 'react';

import { DateTime } from 'luxon';

import { getUserInfo, getManagerCompany } from './ajax';

import LoadingCenter from './LoadingCenter';

import CompanyContainer from './CompanyContainer';


class ManagerDashboard extends React.Component {
  constructor(props) {
    super(props);
    let now = DateTime.utc();
    this.state = {
      state: 'loading', // 'loading', 'loaded'
      company: null,
      endDate: DateTime.fromISO(now).startOf('day').toFormat('yyyy-LL-dd'),
    };
    this.onCompanyChange = this.onCompanyChange.bind(this);
  }
  onCompanyChange(company) {
    try {
      this.setState({ company });
    }
    catch(e) {
      console.log('[ManagerDashboard] Company changed.', company);
    }
  }
  async componentDidMount() {
    try {
      console.log(`[ManagerDashboard] Running componentDidMount()..`);

      // (1) fetch user, determine companyId
      let user = await getUserInfo();
      // console.log('[ManagerDashboard] Fetched user?', user);

      // (2) request company by companyId
      let companyId = user.companyId;
      if (!companyId) {
        console.error('Missing companyId.');
      }
      let company = await getManagerCompany(user.companyId);
      // sort plans latest first
      let plansLatestFirst = company.plans.sort((a,b) => a.expiresAt > b.expiresAt ? -1 : 1);
      // select 'default' company plans
      for (let plan of company.plans) {
        if (plan.id === plansLatestFirst[0].id) {
          plan.isSelected = true;
        }
        else {
          plan.isSelected = false;
        }
      }
      console.log('[ManagerDashboard] Fetched company.', company);
      this.setState({ state: 'loaded', company });

      // (3) TODO load report for selected company plan

    }
    catch(e) {
      console.error(`[ManagerDashboard] Error in componentDidMount()`, e);
    }
  }

  render() {

    if (this.state.state === 'loading') {
      return (
        <LoadingCenter />
      );
    }

    return (
      <div>
        <CompanyContainer company={this.state.company} onChange={this.onCompanyChange}/>
      </div>
    );
 
  }
}

export default ManagerDashboard;


