
import React from 'react';

export const GFlex = function (props) {

  return (<>
    <div
      style={{
        boxSizing: 'border-box',
        position: 'relative',
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'row',
        ...props?.style,
      }}
    >
      {props?.children}
    </div>
  </>);

};
