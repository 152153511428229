'use strict';

import React from 'react';



// pi3 checkbox component
// <FormCheckbox isChecked={isChecked} isDisabled={isDisabled} label='' onChange={} />
class FormCheckbox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hovered: false, 
    };
    this.handleMouseEnter = this.handleMouseEnter.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);
    // this.handleChange = this.handleChange.bind(this);
  }

  handleMouseEnter() {
    // console.log('mouse entered');
    this.setState({ hovered: true });
  }

  handleMouseLeave() {
    // console.log('mouse left');
    this.setState({ hovered: false });
  }

  // handleChange() {
  //   this.setState(prevState => ({
  //     checked: !prevState.checked
  //   }));
  // }

  render() {
    let { isChecked, isDisabled, label, onChange } = this.props;

    if (isDisabled) {
      return (
        <div>
          <div className={isDisabled ? 'pi3checkbox pi3checkboxDisabled' : 'pi3checkbox'}>

            <input
              type="checkbox"
              style={{ display: 'none' }}
              checked={this.props.isChecked}
              onChange={this.handleChange}
              onMouseEnter={this.handleMouseEnter}
              onMouseLeave={this.handleMouseLeave}
              disabled={isDisabled}
              role="checkbox"
            />

            {
              isChecked && // disabled checked checkbox
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_1317_9226)">
                  <path d="M15.8088 0H2.19119C0.98103 0 0 0.98103 0 2.19119V15.8088C0 17.019 0.98103 18 2.19119 18H15.8088C17.019 18 18 17.019 18 15.8088V2.19119C18 0.98103 17.019 0 15.8088 0Z" fill="#939AA8"/>
                  <path d="M7.06664 14C6.72822 14 6.38981 13.8705 6.13077 13.6123L2.3873 9.86881C1.8709 9.35241 1.8709 8.51431 2.3873 7.99791C2.9037 7.48151 3.7418 7.48151 4.2582 7.99791L7.06664 10.8064L13.4857 4.3873C14.0021 3.8709 14.8402 3.8709 15.3566 4.3873C15.873 4.9037 15.873 5.7418 15.3566 6.2582L8.00251 13.6123C7.74431 13.8705 7.40589 14 7.06664 14Z" fill="white"/>
                </g>
                <defs>
                  <clipPath id="clip0_1317_9226">
                    <rect width="18" height="18" fill="white"/>
                  </clipPath>
                </defs>
              </svg>
            }
            {
              !isChecked && // disabled unchecked checkbox
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_1317_9205)">
                  <g clipPath="url(#clip1_1317_9205)">
                    <path d="M2.19119 0.5H15.8088C16.7428 0.5 17.5 1.25717 17.5 2.19119V15.8088C17.5 16.7428 16.7428 17.5 15.8088 17.5H2.19119C1.25717 17.5 0.5 16.7428 0.5 15.8088V2.19119C0.5 1.25717 1.25717 0.5 2.19119 0.5Z" fill="white" stroke="#939AA8"/>
                  </g>
                </g>
                <defs>
                  <clipPath id="clip0_1317_9205">
                    <rect width="18" height="18" fill="white"/>
                  </clipPath>
                  <clipPath id="clip1_1317_9205">
                    <rect width="18" height="18" fill="white"/>
                  </clipPath>
                </defs>
              </svg>
            }
            <span>{label}</span>
          </div>
        </div>
      );
    }


    return (
      <div
        onClick={() => onChange(!this.props.isChecked)}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
      >
        <div className="pi3checkbox">
          {
            isChecked &&
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_1317_9226)">
                <path d="M15.8088 0H2.19119C0.98103 0 0 0.98103 0 2.19119V15.8088C0 17.019 0.98103 18 2.19119 18H15.8088C17.019 18 18 17.019 18 15.8088V2.19119C18 0.98103 17.019 0 15.8088 0Z" fill="#9A1638"/>
                <path d="M7.06664 14C6.72822 14 6.38981 13.8705 6.13077 13.6123L2.3873 9.86881C1.8709 9.35241 1.8709 8.51431 2.3873 7.99791C2.9037 7.48151 3.7418 7.48151 4.2582 7.99791L7.06664 10.8064L13.4857 4.3873C14.0021 3.8709 14.8402 3.8709 15.3566 4.3873C15.873 4.9037 15.873 5.7418 15.3566 6.2582L8.00251 13.6123C7.74431 13.8705 7.40589 14 7.06664 14Z" fill="white"/>
              </g>
              <defs>
                <clipPath id="clip0_1317_9226">
                  <rect width="18" height="18" fill="white"/>
                </clipPath>
              </defs>
            </svg>
          }
          {
            (!isChecked && this.state.hovered) &&
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_1317_9207)">
                <rect width="18" height="18" rx="2" fill="#F8F0F4"/>
                <g clipPath="url(#clip1_1317_9207)">
                  <path d="M2.19119 0.5H15.8088C16.7428 0.5 17.5 1.25717 17.5 2.19119V15.8088C17.5 16.7428 16.7428 17.5 15.8088 17.5H2.19119C1.25717 17.5 0.5 16.7428 0.5 15.8088V2.19119C0.5 1.25717 1.25717 0.5 2.19119 0.5Z" stroke="#76003B"/>
                </g>
                <rect x="0.5" y="0.5" width="17" height="17" stroke="#76003B"/>
              </g>
              <defs>
                <clipPath id="clip0_1317_9207">
                  <rect width="18" height="18" rx="2" fill="white"/>
                </clipPath>
                <clipPath id="clip1_1317_9207">
                  <rect width="18" height="18" fill="white"/>
                </clipPath>
              </defs>
            </svg>
          }
          {
            (!isChecked && !this.state.hovered) &&
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_1317_9205)">
                <g clipPath="url(#clip1_1317_9205)">
                  <path d="M2.19119 0.5H15.8088C16.7428 0.5 17.5 1.25717 17.5 2.19119V15.8088C17.5 16.7428 16.7428 17.5 15.8088 17.5H2.19119C1.25717 17.5 0.5 16.7428 0.5 15.8088V2.19119C0.5 1.25717 1.25717 0.5 2.19119 0.5Z" fill="white" stroke="#939AA8"/>
                </g>
              </g>
              <defs>
                <clipPath id="clip0_1317_9205">
                  <rect width="18" height="18" fill="white"/>
                </clipPath>
                <clipPath id="clip1_1317_9205">
                  <rect width="18" height="18" fill="white"/>
                </clipPath>
              </defs>
            </svg>
          }
          <span>{label}</span>
        </div>
      </div>

    );

  }
}


function IconDelete() { // pi2: ToolkitCategoryViewItem
  return (
    <div className='actionLink'>X</div>
  );
}

export {
  FormCheckbox,
  IconDelete,
};


