
import React from 'react';
import {valueListToArray} from '../../../../src/utilities/Array.js';

export const GAlign = function (props) {

  return (<>
    <div
      style={{
        display: 'grid',
        gridAutoFlow: 'column',
        placeContent: 'center',
        placeItems: 'center',
        boxSizing: 'border-box',
        position: 'relative',
        width: '100%',
        height: '100%',
        overflow: 'auto',
        ...(valueListToArray(props?.variant).includes('row') ? {
          gridAutoFlow: 'column',
        } : {}),
        ...(valueListToArray(props?.variant).includes('column') ? {
          gridAutoFlow: 'row',
        } : {}),
        ...(valueListToArray(props?.variant).includes('top') ? {
          alignContent: 'flex-start',
          alignItems: 'flex-start',
        } : {}),
        ...(valueListToArray(props?.variant).includes('right') ? {
          justifyContent: 'flex-end',
          justifyItems: 'end',
        } : {}),
        ...(valueListToArray(props?.variant).includes('bottom') ? {
          alignContent: 'flex-end',
          alignItems: 'flex-end',
        } : {}),
        ...(valueListToArray(props?.variant).includes('left') ? {
          justifyContent: 'flex-start',
          justifyItems: 'start',
        } : {}),
        ...props?.style,
      }}
    >
      {props?.children}
    </div>
  </>);

};
