
import React from 'react';

import enums from './enums';


function getTypeIcon(type) {
  if (type === enums.CourseLesson.Type.Text) {
    return (
      <div className="icon-lesson-text"></div>
    );
  }
  else if (type === enums.CourseLesson.Type.Video) {
    return (
      <div className="icon-lesson-video"></div>
    );
  }
  else if (type === enums.CourseLesson.Type.Quiz) {
    return (
      <div className="icon-lesson-quiz"></div>
    );
  }
  else if (type === enums.CourseLesson.Type.QuizPlus) {
    return (
      <div className="icon-lesson-quiz"></div>
    );
  }
  else if (type === enums.CourseLesson.Type.Chatflow) {
    return (
      <div className="icon-lesson-text"></div>
    );
  }
}

function getStatusIcon(status) {
  if (status === enums.CourseLesson.Status.Read) {
    return (
      <div className="icon-lesson-read"></div>
    );
  }
  else if (status === enums.CourseLesson.Status.Reading) {
    return (
      <div className="icon-lesson-reading"></div>
    );
  }
  else if (status === enums.CourseLesson.Status.Unread) {
    return (
      <div className="icon-lesson-unread"></div>
    );
  }
  else return '';
}


function MenuCourseProgress(props) {

  let progress = props.progress;
  let course = props.course;

  return (

    <div className='pi3menuCourseStatus'>

      <span className='pi3menuCourseStatusBackground' style={{ width: `${progress.completedPercent}%` }}></span>

      <div className='pi3menuCourseStatusTexts'>

        <svg width="33" height="17" viewBox="0 0 33 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M4.87144 8.02467V8.58548C4.87144 10.873 4.87144 13.1678 4.87144 15.4553C4.88393 15.5594 4.87427 15.6649 4.84311 15.7649C4.81194 15.865 4.75999 15.9572 4.69071 16.0354C4.62143 16.1136 4.53642 16.1759 4.44133 16.2184C4.34624 16.2609 4.24323 16.2825 4.13921 16.2818C4.03725 16.2822 3.93633 16.2611 3.84291 16.22C3.74948 16.1788 3.66559 16.1184 3.59666 16.0427C3.52773 15.967 3.47523 15.8776 3.44256 15.7803C3.40989 15.6829 3.39781 15.5798 3.40698 15.4775V15.1454C3.40698 12.7546 3.40698 10.3638 3.40698 7.97301C3.42059 7.90346 3.4198 7.83185 3.40475 7.7626C3.3897 7.69335 3.36069 7.62796 3.31948 7.57051C3.27827 7.51306 3.22573 7.46476 3.1652 7.42864C3.10467 7.39252 3.03747 7.36938 2.96767 7.36059C2.33795 7.18349 1.73018 6.95474 1.11511 6.75551C0.941844 6.72722 0.784393 6.63716 0.671498 6.50172C0.558603 6.36628 0.497802 6.19448 0.500061 6.01761C0.500061 5.65604 0.675756 5.41991 1.10045 5.27971C6.11377 3.5973 11.1296 1.91487 16.1478 0.232463C16.3675 0.166002 16.6017 0.166002 16.8214 0.232463C21.8152 1.89519 26.809 3.56529 31.8028 5.24278C31.8805 5.27526 31.9563 5.31224 32.0298 5.35348C32.1758 5.40989 32.3002 5.51195 32.3845 5.64474C32.4689 5.77753 32.5088 5.93407 32.4984 6.09138C32.4867 6.25351 32.4223 6.40723 32.3151 6.52865C32.2078 6.65007 32.0638 6.73239 31.9053 6.76285L26.2159 8.67403L17.0044 11.7658C16.6827 11.883 16.3304 11.883 16.0086 11.7658C12.4353 10.5409 8.85724 9.32337 5.2742 8.11321L4.87144 8.02467Z" fill="white"/>
        </svg>

        <h3>
          <a href={`/course/${course.id}/certificate`}>
            {progress.completedPercent}% Course Complete
          </a>
        </h3>

        <svg width="15" height="25" viewBox="0 0 15 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M0.903139 1.20294C1.8404 0.265685 3.35999 0.265685 4.29725 1.20294L13.8973 10.8029C14.8345 11.7402 14.8345 13.2598 13.8973 14.1971L4.29725 23.7971C3.35999 24.7343 1.8404 24.7343 0.903139 23.7971C-0.0341193 22.8598 -0.0341193 21.3402 0.903139 20.4029L8.80608 12.5L0.903139 4.59706C-0.0341193 3.6598 -0.0341193 2.1402 0.903139 1.20294Z" fill="white"/>
        </svg>

      </div>


    </div>


  );
}




export {
  getTypeIcon,
  getStatusIcon,
  MenuCourseProgress,
};