'use strict';

import React from 'react';


// TODO rename to SectionCompletionGraph
class QuizCompletionGraph extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  render() {
    let sections = [];
    let data = this.props.data;

    for (let section of data) {
      sections.push(
        <div key={section.id} className='quizCompletionGraphItem'>
          {/* <td>{section.quizName}</td> */}
          <span className='scorePassed'>{section.passed}</span>
          <span className='scoreFailed'>{section.failed}</span>
        </div>
      );
    }


    return (
      <div className='quizCompletionGraph'>
        {sections}
      </div>
    );
  }
}

export default QuizCompletionGraph;