
import React from 'react';

export const GSelectPlaceholder = function (props) {

  return (<>
    {/* @see https://stackoverflow.com/questions/5805059/make-a-placeholder-for-a-select-box/5859221#5859221 */}
    <option disabled="disabled" hidden="hidden" value="">
      {props?.children}
    </option>
  </>);

};
