
import React from 'react';

export const GFlexItem = function (props) {

  return (<>
    <div
      style={{
        // Having `flex: '1 1 0%'` on all flex items ensures that some can have `width: 'auto'` or `height: 'auto'`
        // with expected behavior.
        // As `flex: '1 1 0%'` ignores initial size it also ignores it's content size which makes it
        // behave in a more expected way when it contains dynamic size content.
        // Having `width: 'full'` and `height: 'full'` makes the flex item grow by default.
        // As all items have this by default they will take up equal space by default.
        // In order to make them take less space `width: 'auto'` and `height: 'auto'` can be used.
        flex: '1 1 0%',
        boxSizing: 'border-box',
        position: 'relative',
        width: '100%',
        height: '100%',
        overflow: 'auto',
        ...props?.style,
      }}
    >
      {props?.children}
    </div>
  </>);

};
