
export const useAPI = function () {

  return {

    get: async (options) => {

      try {

        console.log('[API] GET', options.url);

        const response = await fetch(options.url, {
          headers: {'Accept': 'application/json'},
          method: 'GET',
        });

        if (!response.ok) {
          console.error('[API] Error fetching', options.url);
          throw response;
        }

        return await response.json();

      } catch (e) {
        console.error('[API] Error fetching', options.url, e);
        throw e;
      }

    },

    post: async (options) => {

      try {

        console.log('[API] POST', options.url);

        const response = await fetch(options.url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
          },
          body: JSON.stringify(options.body),
        });

        if (!response.ok) {
          console.error('[API] Error fetching', options.url);
          throw response;
        }

        return await response.json();

      } catch (e) {
        console.error('[API] Error fetching', options.url, e);
        throw e;
      }

    },

    delete: async (options) => {

      try {

        console.log('[API] DELETE', options.url);

        const response = await fetch(options.url, {
          headers: {'Accept': 'application/json'},
          method: 'DELETE',
        });

        if (!response.ok) {
          console.error('[API] Error fetching', options.url);
          throw response;
        }

        return await response.json();

      } catch (e) {
        console.error('[API] Error fetching', options.url, e);
        throw e;
      }

    },

  };

};
